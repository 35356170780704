@use '.././../themes/variables.scss' as vars;

.mock-test-cards {
  height: auto;
  width: calc(100% + 71px) !important;
  margin: -13px -36px;
  float: left;
  overflow: visible !important;
  padding: 0 !important;
  @include vars.respond-below(sm) {
    width: 100% !important;
    margin: 0;
  }
  @include vars.respond-between(sm, md) {
    width: 100% !important;
    margin: 0;
  }
  @include vars.respond-between(md, lg) {
    width: 100% !important;
    margin: 0;
  }

  .test-details-series {
    padding: 0 !important;

    .tabs-header {
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
      margin-bottom: 20px;

      @include vars.respond-below(sm) {
        .MuiTabs-fixed {
          overflow-y: auto !important;

          button {
            span {
              font-size: 9px;
            }
          }
        }
      }
    }

    .MuiExpansionPanelDetails-root {
      display: inline-block;
      width: 100%;
      float: left;
      @include vars.respond-below(sm) {
        padding: 0;
      }
      @include vars.respond-between(sm, md) {
        padding: 0;
      }
      @include vars.respond-between(md, lg) {
        padding: 0;
      }

      .card-list {
        padding: 0 !important;
        padding-top: 10px !important;
        margin-bottom: 15px;
        min-height: 320px;
        @include vars.respond-below(sm) {
          margin: 0;
          width: 100%;
          margin-bottom: 10px;
        }
        @include vars.respond-between(sm, md) {
          width: 48%;
          height: 320px;
        }

        .label-field {
          padding-left: 10px;

          .mock {
            position: relative;
            border-radius: inherit;
            padding-left: 27px;
            top: 0;

            &:after {
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              border-left: 19px solid vars.$white-color;
              content: '';
              position: absolute;
              left: -3px;
              top: 0;
            }
          }
        }

        h4 {
          padding: 10px;

          &:after {
            display: none;
          }
        }

        .tags {
          padding-left: 10px;

        }

        .date-time {
          padding: 0px;
          padding-bottom: 0;
          padding-top: 8px;
        }

        .card-details {
          padding: 10px;

          ul li {
            padding: 0.3rem 0;
          }
        }

        .card-btn {
          position: relative !important;
          bottom: 4px;

          button {
            background: vars.$secondary-color !important;
            margin: 0 !important;

            &:disabled {
              opacity: 0.5;
            }
          }
        }
      }
    }

    .saved-questions {
      h4 {
        font-size: 14px;
        text-transform: initial;

        div {
          p {
            padding: 0;
          }
        }
      }
    }
  }

  .saved-question-list {
    margin-top: 0;
  }

  .no-data-img {
    img {
      height: inherit !important;
    }
  }
}

.genus-text {
  color: #000000;
  opacity: 60%;
  font-weight: 500;
  margin: 0;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30ch;

  @media (max-width: 600px) {
    font-size: 14px;
  }
  @media (min-width: 960px) and (max-width: 1416px) {
      font-size: 12px;
  }
}

.certificate-box {
  cursor: 'pointer';
}

.scrollable-container {
  overflow: 'auto',
}

.certifiedText {
  color: #F75151;
  font-size: 12px !important;
  font-weight: 500 !important;
  font-style: italic !important;
  margin: 12px 0 -3px !important;
}

.certificate-text-div {
  display: flex;
  border: 0px solid rgba(231, 231, 231, 0.933);
  margin: 15px 5px;
  padding: 17px 14px;
  user-select: none;
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 1.95px 1.95px 2.6px 0px rgba(0, 0, 0, 0.15);
  &.mobile-certificate-text-div {
    display: none;
    flex-wrap: wrap;
    padding: 0;
    .certificate-main-div {
      padding: 10px;
      .live-text {
        .genus-text {
          font-size: 11px;
        }
        .best-score {
          font-size: 10px;
        }
      }
    }
    .completion-box {
      align-items: center;
      padding-right: 10px;
      gap: 0;
      span {
        font-size: 10px;
        padding-left: 10px;
      }
    }
    .btn-container {
      width: 100%;
      border-radius: 0px 0px 8px 8px;
      background: #EFF2F3;
      box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.10);
      display: flex;
      button {
        width: inherit;
        flex: 1;
        background-color: transparent !important;
        border-color: transparent !important;
        color: #0C3450 !important;
        padding: 0 !important;
        font-size: 10px !important;
        span {
          color: #0C3450 !important;
          font-size: 10px !important;
        }
      }
    }
  }
  @media (max-width: 767px) {
    height: inherit;
    display: none;
    &.mobile-certificate-text-div {
      display: flex;
    }
  }
  @media (min-width: 960px) and (max-width: 1416px) {
      margin: 8px 0;
      height: 80px;
  }
}

.button-disabled {
  border: 0;
  width: -webkit-fill-available;
  background-color: transparent;
  text-align: left;
}

.bg-disable {
  background-color: #ececec;
  width: -webkit-fill-available;
  cursor: not-allowed;
  opacity: 0.7;
}

.certificate-main-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 11px;
  left: 14px;   
  top: 14px;
  .certificate-default-img-container {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #124E781A;
    svg {
      width: 30px;
      height: 30px;
    }
  }
  @media (min-width: 960px) and (max-width: 1416px) {
      gap: 3px;
  }
}

.certificate-img {
  width: 30px;
  height: 30px;
}

.live-text {
  display: grid;
  margin-left: 16px;
}

.best-score {
  color: #000000;     
  font-weight: 300;
  margin: 0;
  font-size: 12px;
  opacity: 0.8;
}

.completion-box {
  display: flex;
  margin-left: auto;
  margin-right: 2px;
  font-size: 14px;
  align-items: center;
  gap: 16px;
  @media (min-width: 960px) and (max-width: 1416px) {
      font-size: 14px;
  }
}

.completion {
  display: flex;
  color: #000000;
  // opacity: 0.6;
  font-weight: 600;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  @media (max-width: 500px) {
    font-size: 14px;
  }
}

.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  padding: 20px;
  width: auto;
  margin: 0 !important;
 
  @media (max-width: 1024px) {
    width: 100%;
    margin-left: 0;
    padding: 20px !important;
  }
  &.copy-link-modal {
    @media (max-width: 767px) {
        width: 100%;
        margin-left: 0;
        padding: 20px !important;
        margin: auto !important;
        height: 100svh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
  }
}

.modal-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 20px 40px;
}

.modal-heading-text {
  font-weight: normal;
  color: black;
  text-align: center;
}

.ok-btn  {
  font-weight: 500;
  padding: 10px 30px;
  font-size: 15px;
  margin: 15px;
  background-color: #0c3450;
  color: white;
  cursor: pointer;
}

.ok-align {
  display: flex;
  flex-direction: row;
}

@media (max-width: 767px) {
  .mock-test-cards {
    width: 100% !important;
    margin: 0;

    .test-details-series {
      padding: 10px 0 !important;

      .tabs-header {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        border-top: 1px solid #ddd;
        background-color: #fff;
        z-index: 999;
        margin-bottom: 0;

        .MuiTabs-indicator {
          background-color: transparent !important;
        }

        button {
          padding: 6px 1px;
          min-height: 60px;
        }
      }

      .MuiExpansionPanelDetails-root {
        padding: 8px 10px 24px;
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
        width: calc(100% - 10px);

        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }

        .card-list {
          min-width: 280px;
          margin-right: 10px;
          box-shadow: none;
          border: 1px solid #eee;
        }
      }
    }
  }
}
