@use '../themes/variables.scss' as vars;
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.breadcrumb {
  color: #2962ff;
  font-weight: bold !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.powered-by {
  text-align: center;
  color: vars.$white-color;
  font-size: 14px !important;
  font-weight: 500 !important;
  position: fixed;
  bottom: 0;
  left: 30px;
  display: flex;
}
#habilelabs-text {
  color: vars.$white-color !important;
  font-size: 16px;
  display: contents;
  &:hover {
    color: vars.$white-color;
    text-decoration: underline !important;
  }
}
.MuiDialogContent-root + div {
  width: 0 !important;
  height: 0 !important;
}

.listing a .MuiListItemIcon-root, .listing a i {
  width: 25px !important;
  margin-right: 16px !important;
  float: left !important;
  min-width: 25px !important;
}

.listing a {
  color: vars.$white-color !important;
  border-radius: 5px;
}
.listing a svg * {
  fill: vars.$white-color !important;
}
.listing a > div {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
@media (max-width: 959px) {
  .powered-by {
    color: #4c4848bd;
    left: 35px;
  }
  #habilelabs-text {
    color: #282828 !important;
    &:hover {
      color: #282828;
    }
  }
}
