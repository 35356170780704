@use "./../../themes/variables.scss" as vars;

.image-container {
  position: relative;
  width: 100%;
  height: auto;
}

.circle {
  height: 5px;
}

.course-certificate {
  display: flex;
  position: absolute;
  right: -1px;
  top: -85px
}

.my-course-bottom-right {
  position: relative;
  bottom: 28px;
  right: -16px;
}

.course-bottom-right {
  position: absolute;
  top: 10px;
  right: -46px;
  text-align: center;
  transform: rotate(45deg);
  width: 139px;
  background: #0c3450;
  z-index: 0;
}
.course-bottom-right-expire {
  width: 100%;
  display: flex;
  margin: -10px 0px 20px 0px;
  justify-content: space-between;
  min-height: 44px;
  gap: 10px;
}
.course-module {
  margin-top: 14px;
  display: flex;
  min-height: 18px;
}

.course-learning {
  border-radius: 4px !important;
  background: #fff;
  font-size: 12px !important;
  margin-right: 6px !important;
  padding: 0px 8px;
  @media (min-width:392px) and (max-width:480px) {
    font-size: 10px !important;
  }

}

.course-live-learning {
  border-radius: 4px;
  background: #fff;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-left: 5px;
  padding: 0px 8px;
}

.my-course-button {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
}

.my-course-button-expire {
  color: red;
  font-size: 12px;
  display: flex;
  align-items: center;
  min-height: 34px;
}

.ribbon-image {
  height: 25px !important;
  width: 110px !important;
  object-fit: contain !important;
}

.ribbon-text {
  text-transform: uppercase;
  text-align: center;
  display: block;
  padding: 5px;
}

.ribbon-text-expire {
  padding-left: 6px;
  text-align: left;
  color: #F75151;
  @media (max-width:767px) {
    max-width: 100% !important;
  }
}

.certified-text {
  padding-left: 6px;
   color: #009580;
   font-size: 12px;
   @media (min-width:392px) and (max-width:480px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 7ch;
  }
}

.image-fix {
  display: flex;
  text-align: center;
  background: #f1f1f1;
  // border: 1px solid #f1f1f1;
  height: 158px;
}

.section-header {
  padding: 0px 20px;
  font-size: medium;
  font-weight: 500;
}

.select-div-new {
  display: flex;
  flex-direction: row;
  background: #f1f1f1;
  justify-content: space-between;
  @media (max-width: 767px) {
    display: none;
  }
}

.select-div-header {
  display: flex;
  flex-direction: row;
  background: #f1f1f1;
  justify-content: space-between;
}

.discount-div {
  display: flex;
  margin: 0;
  padding: 0px 10px;
  flex-direction: row;
  justify-content: space-between;
}

.price-div {
  display: flex;
  margin: 0;
  padding: 0px 10px;
  flex-direction: row;
  justify-content: space-around;
}

.free-div {
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: row;
  justify-content: space-around;
}

.discount-div-modal {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0px 5%;
  max-height: 100%;
  min-height: 100%;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid lightgray;
}

.free-div-modal {
  display: flex;
  align-items: flex-end;
  max-height: 100%;
  min-height: 100%;
  margin: auto;
  padding-top: 20px;
  flex-direction: row;
  justify-content: space-around;
}

.card-btn-modal {
  position: static;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 0;
  width: 100%;

  Button {
    font-weight: 600;
    background-color: vars.$secondary-color;
    color: #fff;
    width: 100%;
    font-size: 13px;
    text-transform: capitalize;
    border-radius: 5px;

    &:disabled {
      cursor: no-drop;
      color: #ffffff;
      opacity: 0.5;
    }

    &:hover {
      background-color: vars.$secondary-color;
      opacity: 0.95;
    }

    a {
      color: #fff;
      text-decoration: none;
    }
  }
}

.card-title {
  overflow: hidden;
  margin: 10px 0px 0px 0px !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 18px;
  text-transform: capitalize;
  color: #202020;
  font-weight: 600;
  @media (max-width: 540px) {
    font-size: 12px;
    font-weight: 700;
    min-height: 20px;
  }
}
.card-title-progress {
  overflow: hidden;
  margin: -10px 0px 0px 0px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 18px;
  text-transform: capitalize;
  color: #202020;
  font-weight: 600;
  @media (max-width: 540px) {
    font-size: 12px;
    font-weight: 700;
    min-height: 20px;
  }
}
.see-more-button {
  margin: 20px 0px 0px 0px;
  border: none;
  background: none;
  padding: 0px;
  position: relative;
  cursor: pointer;
  width: max-content;
  font-weight: 600;
  font-size: 12px;
}
.see-more-recent-dashboard-button {
  margin: 20px 0px 0px 0px;
  border: none;
  background: none;
  padding: 0px;
  position: relative;
  width: max-content;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  span {
    color: rgba(0, 0, 0, 0.5) !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  @media (max-width: 410px) {
    width: 100%;
  }
}
.card-description {
  min-height: 24px;
  overflow: hidden;
  padding-top: 2px;
  line-height: 17px;
  margin: 0px; 
  display: flex;
  align-items: center;
  margin-top: -4px;
  color: vars.$content-color;
  font-size: 12px;
  font-weight: 275;
  white-space: nowrap;
  text-overflow: ellipsis;
  p {
    color: vars.$content-color;
    font-size: 12px;
    font-weight: 275;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0px;
  }
  a {
    color: vars.$content-color;
    font-size: 12px;
    font-weight: 275;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  span {
    color: rgba(0, 0, 0, 0.5) !important;
  }
}
.card-description-align {
  line-height: 18px;
  padding-top: 0px;
}
@media (min-width: 900px) and (max-width: 1024px) {
  .card-description-align {
    font-size: 16px;
    margin-left: 16px;
  }
}
.card-list-div {
  margin: 0px 16px 0px 16px;
  padding-top: 20px;
}

.card-view-new {
  &.test-series-new {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-gap: 20px;
    height: auto;
    padding-bottom: 6rem;
    @media (max-width: 835px) {
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    }
    @media (max-width: 767px) {
      grid-template-columns: initial;
      padding-bottom: 0;
    }

    img {
      width: 100%;
      max-width: 100% !important;
    }

    .card-list {
      img {
        height: 140px;
        @media (max-width: 540px) {
          height: 140px;
        }
      }

      &:hover {
        color: #000;
      }
      // }
    }
  }
}

.live-image {
  margin-right: 3px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    padding: 0px !important;
    height: 10px !important;
    width: 13px !important;
  }
}

.card-list {
  // padding: 16px;
  padding: 0px 16px 16px 16px;
  // margin-top: 2px;
  max-width: -webkit-fill-available;
  // background-color: white;
  // box-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 4px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  border-radius: 0px 0px 4px 4px;
}

.card-list-image {
  margin-top: 2px;
  max-width: -webkit-fill-available;
  background-color: white;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 4px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}

.card-btn-new {
  position: static;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 0;
  width: 100%;
  margin-top: 4px;

  Button {
    font-weight: 600;
    margin-top: 2px;
    display: block;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
    text-transform: capitalize;
    border-radius: 4px;
    &:disabled {
      cursor: no-drop;
      color: #ffffff;
      opacity: 0.5;
    }

    a {
      color: #fff;
      text-decoration: none;
    }
    &:hover {
      background: #0c3450e0 !important;
    }
    @media (max-width: 767px) {
      font-size: 12px;
      // width: 65%;
      width: 100%;
      margin-top: 10px;
    }
  }
}

.link-btn {
  position: static;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 0;
  width: 100%;
  margin-top: 10px;

  Button {
    font-weight: 600;
    color: #0c3450;
    width: 70%;
    display: block;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
    text-transform: capitalize;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #0c3450;
    box-shadow: 2px 4px 8px 0px rgba(157, 172, 254, 0.2);
    &:disabled {
      cursor: no-drop;
      color: #ffffff;
      opacity: 0.5;
    }

    a {
      color: #fff;
      text-decoration: none;
    }
  }
}

.hideInMobile-new {
  display: flex;
  width: 100%;
  margin-top: auto;
  @media (max-width: 768px) {
    display: none;
  }
}

.card-modal-box {
  width: 500px;
  height: 80%;
  background-color: #fff;
  box-shadow: 5px 5px 5px rgba(91, 91, 91, 0.5);
  border-radius: 10px;
  @media (max-width: 425px) {
    width: 90%;
  }
}

.card-modal-header {
  height: 40%;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #0c3450;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    height: 55% !important;
    border-radius: 5px;
  }

  p {
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 15px;
    padding: 0px 10px;
    margin: 5px 0px 0px 0px;
    min-height: 35px;
    font-weight: 600;
  }
}

.card-modal-content {
  height: 49%;
  padding: 0px 10px;
  max-height: 49%;
  overflow-y: scroll;

  div {
    margin: 10px 0px;

    div {
      padding-left: 10px;
    }
  }

  p {
    font-weight: 600;
    margin: 5px;
  }
  ul {
    margin: 0;
    padding: 0px 30px;
  }
}

.card-modal-footer {
  height: 11%;
}

.live-class-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 16px;
  font-weight: bold;
  margin: 0px 0px 0px 0px;
  min-height: 47px;
  max-height: 47px;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #0c3450 !important;
}

.MuiTypography-body2 {
  color: #0c3450 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.MuiLinearProgress-root {
  border-radius: 30px;
  height: 5px !important;
}

.MuiTab-root {
  padding: 0px !important;
  min-width: 50px !important;
}

.title {
  margin-top: 23px;
  padding: 0px 26px 23px 30px;
  color: #202020;
  font-size: 36px;
  font-weight: 500;
  line-height: normal;
}

.header {
  display: flex !important;
}

.container-div {
  width: 100%;
}

.certificates-container {
  gap: 15px;
  margin-top: 8px;
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  @media (max-width: 687px) {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
}

.certificate-card {
  border: 1px solid rgba(231, 231, 231, 0.91);
  background: #fff;
  border-radius: 6px;
}

.content-container {
  padding: 15px 15px 5px 15px;
}

.certificate-name {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  margin-top: 15px;
  min-height: 36px;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.certificate-details {
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
}

.download-button {
  border: none;
  margin: 15px auto;
  display: flex;
  padding: 7px 27px;
  background: #0c3450;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 0 25px 0 rgba(12, 52, 80, 0.35);
  &:hover {
    background: #0c3450e0;
  }
}

.download-button-text {
  font-weight: 600;
  display: block;
  color: #ffffff;
  align-items: center;
  font-size: 14px;
}

.certificate-description {
  color: #000;
  width: 100%;
  min-height: 8px;
  margin: -6px 0px 10px 0px;
  display: -webkit-box;
  overflow: hidden;
  font-size: 12px;
  font-weight: 300;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-height: 14px;
  p {
    margin: 0px;
  }
}

.certificate-score {
  font-size: 12px;
  font-weight: 400;
}

.score {
  font-size: 12px;
  font-weight: 500;
  margin-left: 6px;
}

.certificate-completion-date {
  font-size: 12px;
  font-weight: 400;
  margin-left: 5px;
}

.completion-date {
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
}

.certificate-desc {
  margin: 0px;
  margin-top: 6px;
}

@media (min-width: 768px) and (max-width: 899px) {
  .header {
    margin-top: 68px;
    margin-bottom: 10px;
  }
}

@media (min-width: 900px) and (max-width: 900px) {
  .header {
    margin-top: 28px;
    margin-bottom: 10px;
  }
}

@media (min-width: 901px) and (max-width: 959px) {
  .header {
    margin-top: 74px;
    margin-bottom: 10px;
  }
}

@media (max-width: 420px) {
  .title {
    padding: 0px 26px 23px 10px;
  }
}
