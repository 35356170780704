@use './../../themes/variables.scss' as vars;
.header-title {
    color: #0C3450;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    margin: 0;
    padding: 20px 0;
    margin-left: 20px;

    @media (max-width: 900px) {
        font-size: 20px;
    }
}
.sub-heading-result {
    color: #84818A;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
}

.custom-grid {
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 24px;
}

.heading-title {
    color: #5A5E7C;
    font-size: 24px;
    font-weight: 400;
    text-align: left !important;
    padding-bottom: 10px;
    @media (max-width: 1200px) {
        font-size: 18px;
    }
}

.custom-subtitle {
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #0C3450;
    padding-bottom: 6px;
    @media (max-width: 767px) {
        font-size: 24px;
    }
}

.card-data {
    padding: 20px;
}

.card-detail {
    border-radius: 15px !important;
    box-shadow: none !important;
    img {
        width: 30px;
        height: 30px;
        display: flex;
        margin-left: auto;
    }
}

.attempt-bind {
    padding-bottom: 100px;
    @media (max-width: 767px) {
        padding-right: 16px;
    }
}
.attempt-table-heading {
    font-size: 16px;
    font-weight: 600 !important;
    line-height: 29px !important;
    color: #5A5A5A;
    padding-bottom: 8px;
    text-align: left;
}
.attempt-table-sub-heading {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 29px !important;
    color: #555555;
    padding-bottom: 8px;
    text-align: left;
}
.selected-attempt{
    border: 0px !important;
    td {
        border-bottom: 0px !important;
    }   
}
.attempt-card-box {
    margin-right: 15px;
    margin-left: 15px;
    border-radius: 8px !important;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.06) !important;
    border: 1px solid #F5F5F5 !important;
    background-color: vars.$white-color;
    padding: 0 !important;
    .table-data {
        border: none;
        box-shadow: none;
    }
    .table-container{
        box-shadow: none !important;
    }
    h2{
        font-size: 18px;
        font-weight: 400; 
        text-align: left;
        margin: 0;
        padding: 20px 15px;
        @media (max-width: 767px) {
            font-size: 14px;
        }
    }
    .column-header {
        font-size: 14px;
        font-weight: 500;
        line-height: 29px;
        text-align: left;
        color: rgba(0, 0, 0, 0.87);
    }
    .body-row {
        td {
            font-size: 14px !important;
            font-weight: 400 !important;
            text-align: left;
            color: rgba(0, 0, 0, 0.87);
            background-color: #ffffff;
        }
    }
    .dropdown-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 15px;
        h2 {
            padding: 0 !important;
        }
    }
    .dropdown-data {
        width: 160px;
        .MuiSelect-select {
            color:#84818A;
            font-size: 16px;
            font-weight: 400;
        }
    }
    .dropdown-data:hover .MuiSelect-select {
        cursor: pointer;
    }
    .MuiSelect-select:focus {
        background-color: #ffffff;
    }
    .MuiInput-underline:before {
        border-bottom: none;
        transition: none;
    }
    .MuiInput-underline:after {
        border-bottom: none;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: 1px solid #E7E7E7;
    }
    @media (max-width: 767px) {
        margin-right: 0 !important;
    }
}

.main-box {
    background-color: #ffffff;
    padding: 15px;
    margin-bottom: 35px;
    height: 445px;
}

.main-section {
    height: 445px;
    background-color: #ffffff;
}


.title {
    margin: 0;
    padding: 0;
    padding-bottom: 40px;
    font-size: 22px;
    font-weight: 700; 
    text-align: left;
}

.title-heading {
    padding: 0 0 20px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 900px) {
        font-size: 14px;
    }
}

.custom-tooltip {
    width: 60px;
    height: 60px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0C3450 !important;
    font-weight: 600;
    font-size: 14px;
    opacity: 1;
    border: none;
    box-shadow: 0px 0px 19px 0px #22282F14;
}


.legend {
    padding-top: 50px;
}
.legend-item {
    display: flex;
    align-items: center;
    height: 0px;
    padding-bottom: 35px;
    p {
        color: #7C828A;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
    }
}

.legend-circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 5px;
}

.distribution-grid {
    // gap: 24px;
    padding-bottom: 24px;
    justify-content: space-around;
    @media (max-width: 1200px) {
          display: block !important;
    }
    @media (max-width: 767px) {
        padding-bottom: 0 !important;
    }
}

.section-data-grid {
    // background-color: #ffffff;
    // margin-left: 15px !important;
    @media (max-width: 1200px) {
        max-width: 100% !important;
    }
}

.data-grid {
    // background-color: #ffffff;
    padding: 20px 20px 20px 15px;
    
    @media (max-width: 1200px) {
        max-width: 100% !important;
        margin: 15px !important;
    }
    @media (max-width: 767px) {
        margin-right: 0 !important;
    }
}

.chart-data {
        display: block;
        margin: 0 auto;
}

.card-title {
    font-size: 14px;
    font-weight: 600;
    color: #0C3450;
    margin-top: 0 !important;
    padding-bottom: 0 !important;
}

.card-content {
    padding: 0 !important;
}

.graph-box-container {
    padding: 20px 15px; 
    background-color: vars.$white-color;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.06);
    border: 1px solid #F5F5F5;
    display: flex;
    flex-direction: column;
   
}
.legends-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
        
    @media (max-width: 767px) {
        flex-direction: column;
        .chart-data {
            margin-left: auto !important;
        }
        .legend {
            display: flex;
            column-gap: 10px;
            .legend-item {
               .legend-circle {
                width: 10px;
                height: 10px;
               }
               p {
                font-size: 10px;
               }
            }
        }
    }
 }