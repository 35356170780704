@forward './styles/common.scss';
@use './themes/variables.scss' as vars;
.App {
  text-align: center;
  scrollbar-width: 5px;
}

.main-div {
  background: #FAFAFA;
    width: 100%;
    height: 100vh;
    // position: fixed; 
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::backdrop {
  background-color: #fff;
}

.main-division {
  display: flex;
  width: 100%;
  padding: 20px 16px 0px 16px;
  @media(max-width: 767px){
    padding-top: 80px;
  }
}

.main-body {
  width: 100%;
}

.main-right-sidebar {
  width: 30%;
  overflow: scroll;
  &.w-full {
    width: 100%;
  }
}
.course-count-main-box{
  border: 1px solid transparent;
  background: #FFF;
  display: flex;
  cursor: pointer;
  // gap: 32px;
  gap: 0px;
  min-height: 115px;
  justify-content: center;
  width: 100%;
  padding: 10px 16px 10px 16px;
  border-radius: 6px;
  transition: .3s all linear;
  box-shadow: vars.$card-box-shadow;
  @media(max-width: 900px){
    display: block;
    width: 100%;
    gap: 0px;
    svg{
      display:  block;
      margin: auto;
      @media(max-width: 900px){
        width: 65px;
      }
    }
    .course-count-box{
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
}
@media (max-width: 1100px){
  .course-count-main-box{
    padding: 10px 0px 10px 0px;
    gap: 0.5vw;
  }
}
.course-completed-align {
  @media(max-width: 959px){
    margin-right: 0px;
  }
}
@media (min-width: 550px) and (max-width: 680px){
  .course-count-main-box{
    min-height: 173px;
  }
}

@media (min-width: 1434px) and (max-width: 1572px){
  .course-count-main-box{
    min-height: 123px;
  }
}

@media (min-width: 1101px) and (max-width: 1279px){
  .course-count-main-box{
    gap: 0px;
    padding: 10px 6px 10px 10px;
  }
}

@media (min-width: 1280px) and (max-width: 1440px){
  .course-count-main-box{
    gap: 10px;
  }
}

@media (max-width: 767px) {
  .course-count-main-box {
    display: flex;
    align-items: center;
    margin-right: 0;
  }
  .main-div {
    height: inherit;
    min-height: 100svh;
    padding-bottom: 80px;
  }
  * ::-webkit-scrollbar {display:none;}
}
::-webkit-scrollbar {display:none;}

.commonShadow {
  box-shadow: vars.$card-box-shadow !important;
  border: 1px solid transparent !important;
  background-color: #fff !important;
  border-radius: 8px !important;
}

.linearProgressDiv {
  width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.mobile-view-password-spacing {
  @media (max-width: 767px) {
    padding-bottom: 0;
  } 
}