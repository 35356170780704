.forget-title {
  text-align: center;
  font-weight: 600;
  font-size: 32px;
  @media (max-width: 767px) {
    font-size: 24px;
  }
}  
.custom-sub-title {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 20px;
  color: rgba(0, 0, 0, .54);
  }
  .forgot-password-right-side-box {
    padding: 50px 140px;
    background-color: #f7f8f9;
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .send-reset-link-button {
    width: 100%;
    font-size: 16px !important;
    font-weight: 600 !important;
    border-radius: 3px !important;
    padding: 10px 20px !important;
    border: none !important;
    cursor: pointer !important;
    height: 48px;
    text-transform: capitalize !important;
  }
  .forgot-password-parent-div {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    padding: 0px;
    margin: auto;
    height: 100%;
  }
  .forgot-password-child-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    width: 50%;
    background-color: #fff;
  }
  .forgot-password-logo {
    max-width: 100%;
    height: auto;
    width: 300px;
    margin-bottom: 40px;
  }
  .send-reset-link-div {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .back-login {
    cursor: pointer;
    text-align: center;
    margin-bottom: 12px;
    text-decoration: none;
    color: #0c3450 !important;
    font-size: 16px;
    margin-left: auto;
    margin-top: 20px;
    font-weight: 600;
    margin-bottom: 50px;
  }

  .mdc-text-field__label {
    font-size: 16px;
    position: absolute;
    top: -3px;
  }

  .input-box {
    display: block;
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 6px 12px;
    border-radius: 4px;
    width: 100%;
    background: none;
    text-align: left;
    color: rgba(0, 0, 0, 12);
    border: 1px solid rgba(0,0,0,.1294117647);
    .Mui-focused {
      border-color: red !important;
    }
  }

  .MuiInput-underline:before {
    border-bottom: none !important;
    transition: none !important;
  }

  .MuiInput-underline:after {
    transition: none !important;
    border-bottom: 1px solid rgba(0,0,0,.1294117647) !important;
  }

  .Mui-focused {
    outline: none !important;
    border-bottom: 1px solid rgba(0,0,0,.1294117647) !important;
    box-shadow: none !important;
    transition: none !important;
  }
  
  .disabled-btn {
    background-color: #6B728075 !important;
    color: #fff !important;
    cursor: not-allowed !important;
    pointer-events: none; /* Prevent any interaction */
}

  #login-img-learn {
    width: 300px !important;
  }
  @media only screen and (max-width: 767px) {
    #login-img-learn {
    width: 140px !important;
    }
    .forgot-password-parent-div {
      flex-direction: column;
      background-color: #fff;
      height: 100svh;
    }
    .forgot-password-right-side-box{
      padding: 0 15px;
      width: 100%;
      display: block;
      background-color: transparent;
    }
    .forgot-password-child-div {
      padding: 0px;
      height: 20vh;
    }
    .forgot-title {
      display: none;
    }
    .forgot-password-logo {
      margin-bottom: 0px;
    }
  }
  @media only screen and (max-width: 1300px) {
    .forgot-password-right-side-box {
      padding: 50px 15px;
    }
  }