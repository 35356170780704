@use '../themes/variables.scss' as vars;

.downloadApp {
  padding: 10px;
  color: #008e76;
  background: #008e7612;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: 600;
  border-radius: 5px;
  margin-top: 10px;
  display: none;
  @media (max-width: 767px) {
    display: flex;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    z-index: 99;
    position: fixed;
    bottom: 0;
    display: flex;
    left: 0;
    right: 0;
    border-radius: 0px;
    margin: 0;
    color: #fff;
  }
}

.dashboard-image-loader {
  margin: 0px 0 30px;

  svg {
    height: 100%;
    width: 100%;
  }

  @media (max-width: 767px) {
    height: 200px !important;
  }
  @media (min-width: 768px) {
    height: 340px !important;
  }
  @media (min-width: 1200px) {
    height: 400px !important;
  }
}

.mobile-image-slider {
  margin: 0px 0 30px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 7px;
  @media (max-width: 767px) {
    margin: 0 0 20px;
  }

  button {
    width: 10px !important;
    height: 10px !important;
  }

  > div {
    background-color: transparent !important;
    @media (min-width: 768px) {
      height: 350px !important;
    }
    @media (min-width: 1200px) {
      height: 400px !important;
    }

    > div {
      position: relative !important;
      height: 100% !important;
      width: 100% !important;

      .rsis-container {
        position: static !important;
        border-radius: 6px;
        overflow: hidden;

        > div {
          background-position: center;
          background-repeat: no-repeat;
          @media (max-width: 767px) {
            background-size: contain !important;
            border-radius: 0;
            overflow: hidden;
          }
          @media (min-width: 768px) {
            background-size: cover !important;
            border-radius: 6px;
            overflow: hidden;
          }
          @media (min-width: 1200px) {
            background-size: cover !important;
            border-radius: 6px;
            overflow: hidden;
          }
        }

        & + div {
          left: 0 !important;
          right: 0;
          margin: 0 !important;
          text-align: center;
        }
      }
    }
  }
}

.dashboard-container {
  //display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
  }

  .dashboard-left {
    width: 100%;
    @media (max-width: 767px) {
      width: 100%;
      margin-top: 10px;
    }
  }

  .dashboard-right {
    width: 25%;
    flex-direction: column;
    display: flex;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
}

.buy-pass-card {
  background-color: #229e89;
  padding: 20px 10px;
  border-radius: 5px;
  margin-bottom: 25px;
  background-image: url('./../assets/images/buy-pass-banner-img.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  @media (max-width: 767px) {
    padding: 20px 15px;
    margin-top: 10px;
  }

  .buy-pass-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (min-width: 768px) and (max-width: 1200px) {
      flex-direction: column;
    }

    img {
      width: 100px;
      margin-left: -7px;
      @media (max-width: 767px) {
        width: 130px;
      }
      @media (min-width: 768px) and (max-width: 1200px) {
        width: 150px;
        margin-bottom: 5px;
      }
    }

    span {
      display: flex;
      justify-content: flex-end;
      color: #fff;
      font-size: 12px;
      text-align: right;
      @media (max-width: 767px) {
        width: 160px;
      }
      @media (min-width: 768px) and (max-width: 1200px) {
        text-align: center;
      }
    }
  }

  .buy-pass-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 25px;
    @media (min-width: 768px) and (max-width: 1200px) {
      flex-direction: column;
      width: 100%;
      padding-top: 14px;
      border-top: 1px dotted #ffffff45;
      margin-top: 14px;
    }

    .buy-pass-rate {
      flex-direction: column;
      display: flex;

      span {
        font-size: 12px;
        font-weight: 400;
        color: #fff;
        padding-bottom: 2px;
        display: flex;
        @media (min-width: 768px) and (max-width: 1200px) {
          justify-content: center;
        }
      }

      p {
        margin: 0;
        font-size: 18px;
        color: #fff;
        font-weight: bold;
      }
    }

    button {
      background-color: #fff;
      color: #008e76;
      font-weight: bold;
      padding: 6px 14px;
      text-transform: capitalize;
      font-size: 13px;
      @media (max-width: 767px) {
        padding: 7px 26px;
      }
      @media (min-width: 768px) and (max-width: 1200px) {
        margin-top: 10px;
        width: 100%;
      }

      :hover {
        background-color: #fff;
      }
    }
  }
}

.free {
  display: none !important;
}

.MuiCollapse-container {
  overflow: auto;
}

.noData {
  height: 500px;
}

.Accordian-detail {
  overflow: auto;
  @media (max-width: 1024px) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.Attempted {
  > div {
    padding: 0 !important;
  }
}

.completed-test-container {
  margin-left: -25px;
  margin-right: -25px;

  .card-view {
    padding: 10px 10px !important;
  }

  @media (max-width: 767px) {
    margin-left: 0;
    margin-right: 0;
    .card-view {
      padding: 10px 0 !important;
    }
  }
}

.mobile-learning-corner {
  display: none;
}

.mobile-test-dashboard {
  display: none;
}

.country-code {
  margin-top: 25px;
  font-size: 16px;
}

.text-field-with-countryCode {
  display: flex;
}

.Header-root-2 {
  background-color: #EFF5F4;
  position: relative;
  top: 0px;
}

.tabs-header {
  .button-mock-tabs {
    svg {
      fill: rgba(0, 0, 0, 0.54)
    }

    &.Mui-selected {
      color: vars.$secondary-color;

      svg {
        fill: vars.$secondary-color;
      }
    }
  }

  .MuiTab-root {
    text-transform: capitalize;
  }
}

.marginTop50 {
  margin-top: 50px;

  &.direction-block {
    margin-top: 0;
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    height: calc(100vh - 200px);
  }
}

.practice-question-block {
  .mobile-sidebar-btn {
    display: none;
  }

  .practice-test {
    width: 75%;
    float: right;

    .practice-question-heading {
      h4 {
        margin: 0;
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid #ddd;
      }
    }
  }

  .sidebar {
    width: 20%;
    position: fixed;
    background-color: #fff;
    top: 66px;
    left: 240px;
    border-right: 1px solid #888;
    height: calc(100vh - 66px);
    transition: all 225ms linear;

    .search-filter {
      width: 100%;
      float: left;
      position: relative;
      padding: 20px 10px;
      background: #fff;
      border-bottom: 1px solid #ddd;

      input {
        width: 100%;
        height: 35px;
        padding: 0 0 0 30px;
        border-radius: 4px;
        box-shadow: none;
        border: 1px solid #ddd;
        outline: none;
      }

      i {
        position: absolute;
        top: 30px;
        left: 17px;
      }
    }

    .chapter-list {
      width: 100%;
      float: left;
      padding: 0;
      height: calc(100vh - 141px);
      overflow-y: auto;
      overflow-x: hidden;

      .accordion {
        border-radius: 0 !important;
        margin: 0;

        #panel1a-header {
          padding: 0 10px;
          padding-top: 5px;
          padding-bottom: 5px;
          min-height: inherit;

          .MuiAccordionSummary-content {
            margin: 0;

            span {
              margin: 0;
              padding: 0;
              line-height: inherit;
              width: 100% !important;
              display: block;
              font-size: 12px;

              .progressBarHigh {
                position: absolute;
                bottom: 6px;
                left: 10px;
                right: 10px;

                div {
                  background-color: vars.$progressBarHigh-color;
                }
              }

              .progressBarMed {
                position: absolute;
                bottom: 6px;
                left: 10px;
                right: 10px;

                div {
                  background-color: vars.$progressBarMed-color;
                }
              }

              .progressBarLow {
                position: absolute;
                bottom: 6px;
                left: 10px;
                right: 10px;

                div {
                  background-color: vars.$progressBarLow-color;
                }
              }
            }
          }

        }
      }

      .MuiCollapse-container {
        .MuiAccordionDetails-root {
          padding: 0;

          .MuiList-root {
            padding: 0;
            width: 100%;

            li {
              padding: 5px 30px;
              cursor: pointer;

              .inner-data-name {
                width: 100%;
                float: left;

                p {
                  margin: 0;
                  font-size: 14px;
                  padding: 0;
                  line-height: inherit;
                }

                span {
                  display: block;
                  font-size: 12px;
                  color: #039be5;

                }
              }

              &.active {
                background-color: #eee;
                border-bottom: none;
              }
            }
          }
        }
      }

      .loader-for-subject {
        width: calc(100% - 0px);

        .loader-content {
          width: 100%;
          float: left;

          svg {
            width: 100%;
            height: 50px;
          }

          .loader-content-list {
            box-shadow: 0 0 5px #ffffff;
            margin-bottom: 2px;
          }
        }
      }

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #039be5;
      }

      ul {
        padding: 0;

        li {
          padding: 10px 0;
          border-bottom: 1px solid #ddd;

          .chapter-name {
            width: 100%;
            cursor: pointer;

            .chapter-details {
              width: 90%;
              vertical-align: middle;
              display: inline-block;
              padding: 0 10px;

              p {
                margin: 0;
                font-size: 14px;
                padding: 0;
              }

              span {
                display: block;
                font-size: 12px;
                color: #039be5;
                padding: 3px 0;
              }
            }

            .chapter-collapsible-icon {
              width: 10%;
              display: inline-block;
              vertical-align: middle;
              padding: 0 10px;

              i {
                font-size: 14px;
                float: right
              }
            }

            .inner-data {
              display: none;
              padding: 5px 15px;

              &.inner-data-open {
                display: block;
                margin: 0;
                padding: 0;
              }

              ul {
                li {
                  padding: 5px 30px;

                  &:hover {
                    background-color: transparent;
                  }

                  &:last-child {
                    border-bottom: none;
                  }

                  &.active {
                    background-color: #eee;
                    border-bottom: none;
                  }

                  p {
                    margin: 0;
                    font-size: 14px;
                    padding: 6px 0;
                  }

                  .activetrue {
                    color: #00a2ff;
                  }

                  span {
                    display: block;
                    font-size: 12px;
                    color: #039be5;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .mobile-search {
    display: none;
  }
}

.test-section {
  width: 100%;
  float: left;
}

.select-filter {
  border-radius: 4px;
  padding: 0 10px;

  .MuiSelect-select.MuiSelect-select {
    color: #fff;
  }

  #select-status {
    color: #fff;
  }

  &:before {
    border-bottom: 0 !important;
  }

  &:after {
    border-bottom: 0 !important;
  }

  svg {
    color: #fff;
  }
}

#menu-status {
  z-index: 9999 !important;
}

.saved-question-section {
  width: 100%;
  float: left;
  padding: 0 30px;

  .saved-question-heading {
    width: 100%;
    float: left;
    justify-content: flex-end;
    padding: 10px 0px;
    display: flex;
    align-items: center;

    h2 {
      margin: 0;
      font-size: 24px;
      font-weight: 500;
    }
  }
}

.loader-for-saved-question {
  width: calc(100% - 10px);

  .loader-content {
    width: 100%;
    float: left;

    svg {
      width: 100%;
      height: 80px;
    }

    .loader-content-list {
      box-shadow: 0 0 5px #ccc;
      margin-bottom: 2px;
      border-radius: 5px;
    }
  }
}


.mock-test-section {

  .mock-tabs {
    width: 100%;
    margin: 0;
    float: left;
    @media (min-width: 768px) and (max-width: 1100px) {
      width: 100%;
      margin: 0;
    }

    .button-mock-tabs {
      svg {
        fill: rgba(0, 0, 0, 0.54)
      }

      i {
        font-size: 22px;
      }

      &.Mui-selected {
        color: vars.$secondary-color;

        svg {
          fill: vars.$secondary-color;
        }
      }
    }

    .tabs {
      color: #000;
      background-color: #fff;
      box-shadow: none !important;
      border-bottom: 1px solid #e9ebf288;
      padding: 0;
      position: static;

      .button-mock-tabs {

        svg {
          fill: rgba(0, 0, 0, 0.54)
        }

        &.Mui-selected {
          color: vars.$secondary-color;

          svg {
            fill: vars.$secondary-color;
          }
        }
      }

      .dashboardTab {
        display: none;
      }
    }

    &.fixed-header {
      .tabs {
        position: fixed;
        top: 64px;

        .button-mock-tabs {
          &.Mui-selected {
            color: vars.$secondary-color;

            svg {
              fill: vars.$secondary-color;
            }
          }
        }
      }
    }

    .AllTest {
      > div {
        padding: 0 !important;

        h2 {
          padding: 0 20px;
          font-size: 18px;
          margin: 22px 0px -14px;
        }
      }
    }

    .Attempted {
      > div {
        padding: 0 !important;
      }
    }

    .MyTest {
      > div {
        padding: 0 !important;
      }
    }

    .Quizes {
      > div {
        padding: 0 !important;
      }
    }

    .SavedQuiz {
      @media (max-width: 1024px) {
        > div {
          padding: 0 !important;
        }
      }

    }
  }
  .PrivateTabIndicator-colorPrimary-33 {
    height: 0;
  }
  .test-heading {
    width: calc(100% + 80px);
    float: left;
    border-bottom: 1px solid #e9ebf288;
    padding-bottom: 0;
    margin: -20px -40px;

    h2 {
      margin: 0;
      padding: 8px 25px 0px;
      font-size: 18px;
    }
  }
}

.header {
  display: none;

  .test-card {
    display: none;
  }
}

@media (max-width: 767px) {
  .Attempted {
    > div {
    }
      padding: 0 !important;
  }
  .Mock-test {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;

    > div {
      padding: 0px !important;
    }
  }
}

@media (max-width: 767px) {
  .allTest {
    margin-top: 0;
    margin-left: 0;

    > div {
      padding: 0px !important;

      h2 {
        padding: 0 20px;
        font-size: 18px;
        margin: 22px 0px -14px;
      }
    }
  }
}

@media (max-width: 767px) {
  .Test-series {
    margin-top: 0;
    margin-left: 0;
    padding: 5px 0;

    & > div {
      padding: 0 !important;
    }
  }

}

@media (max-width: 767px) {
  .test-btn {
    margin-top: 10px;
  }
  .header {
    display: block;

    h2 {
      margin: 0;
      font-size: 15px;
    }

    &.test-container {
      box-sizing: border-box;
      display: flex;
      flex-wrap: nowrap;
      width: 104%;
      padding: 10px 0;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &.test-card {
      min-width: 28% !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto 20px;
      float: left;
      background: #fff;
      padding: 15px 15px;
      margin-right: 3%;
      border-radius: 7px;
      margin-bottom: 20px;
      height: auto;
      box-shadow: 0 0 1px rgba(92, 91, 91, 0.2), 0 4px 10px rgba(0, 0, 0, 0.2);
      position: relative;
      text-align: center;
      box-sizing: border-box;
      justify-content: center;
      padding: 10px;
      flex-shrink: 0;

      img {
        width: 40px;
        height: 40px;
        margin-bottom: 10px;
        object-fit: contain;
      }
    }


  }
  .card-data {
    font-size: 15px;
    display: inline-block;
    clear: both;
    white-space: nowrap;
    color: #ddd;
    @media (max-width: 767px) {
      white-space: break-spaces;
    }
  }

  .card-test {
    font-size: 14px;
    color: #ddd;
    background-color: #0f9680;
    border-radius: 4px;
    padding: 4px;
    border: none;
    width: 80px;
    height: 25px;
    margin-top: 3px;
  }
}

  .course-btn {
    margin-top: 10px;
  }
  .header {
    display: block;

    h2 {
      margin: 0;
      font-size: 15px;
    }

    &.course-container {
      box-sizing: border-box;
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      padding: 10px 0;
      overflow: auto;
      max-width: 1000px;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &.course-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      float: left;
      background: #fff;
      padding: 15px 15px;
      margin-right: 4%;  // 2 to 7%
      border-radius: 7px;
      margin-bottom: 20px;
      height: auto;
      box-shadow: 0 0 1px rgba(92, 91, 91, 0.2), 0 4px 10px rgba(0, 0, 0, 0.2);
      position: relative;
      text-align: center;
      box-sizing: border-box;
      justify-content: center;
      padding: 10px;
      flex-shrink: 0;
      height: 135px;  //swap 250 to 135
      max-width: 135px;
      width: 30%;
      font-weight: bold;

      img {
        width: 40px;
        height: 40px;
        margin-bottom: 10px;
        object-fit: contain;
      }
    }


  }
  .card-data {
    font-size: 15px;
    display: inline-block;
    clear: both;
    white-space: nowrap;
    color: #ddd;
    @media (max-width: 767px) {
      white-space: break-spaces;
    }
  }

  .card-test {
    font-size: 14px;
    color: #ddd;
    background-color: #0f9680;
    border-radius: 4px;
    padding: 4px;
    border: none;
    width: 80px;
    height: 25px;
    margin-top: 3px;
  }

.exam-board {
  width: 100%;
  float: left;
  padding: 10px;
  border-radius: 6px;
  min-height: 150px;
  border: 1px solid #eee;

  &.card-view {
    width: 100%;
    float: right;
    margin-top: 30px;
    height: auto;
    padding: 20px 15px 20px;

    .flexBox-container {
      display: flex;
      width: 100%;
      @media (min-width: 768px) and (max-width: 1100px) {

        display: flex;
        overflow: auto;
        width: 100%;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .card-list {
      .card-btn {
        button {
          border: none;
          cursor: pointer;
          background-color: vars.$secondary-color;
          border-radius: 0 0 5px 5px;

          &:hover {
            background-color: vars.$secondary-color;
            opacity: 0.8;
          }
        }
      }
    }
  }

  &.learning-board {
    width: 100%;
    float: right;
    margin-bottom: 30px;
  }

  &.profile-board {
    width: 100%;
    float: right;
    background: #768fff;
    margin: 0 0 25px;
    min-height: inherit;

    &.mobile-profile {
      display: none;
    }

    .exam-heading {
      margin-bottom: 7px;

      h2 {
        color: #fff;
      }
    }
  }

  &.mock-test-strip {
    margin: 0 0 30px;
    width: 100%;
    padding: 20px 20px 20px;
    display: block;
    @media (min-width: 768px) and (max-width: 1100px) {
      .flexBox-container {
        display: flex;
        overflow: auto;
        width: 100%;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .mock-test-card {
      width: 30%;
      margin-right: 3.33%;
      margin-bottom: 3.33%;
      display: flex;
      flex-direction: column;
      padding: 0;
      min-height: inherit;
      max-height: inherit;

    }
  }

  .exam-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    float: left;
    width: 100%;
    margin-bottom: 5px;

    h2 {
      margin: 0;
      padding: 0;
      font-size: 18px;
    }

    a {
      text-decoration: none;
      font-size: 14px;
      color: #1A155E;
      font-weight: bold;
    }
  }

}


.password-modal.exam-modal {
  .add-exam-list {
    &.change-with-p {
      ul {
        max-height: calc(100vh - 200px);

        li {
          align-items: flex-start;

          button {
            > span.MuiButton-label {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              span {
                align-items: flex-start;
                text-align: left;
              }

              p {
                padding-left: 0;
                border-bottom: 0;
                font-size: 12px;
                text-align: left;
                text-transform: none;
              }
            }
          }
        }
      }
    }
  }
}

.dashboard-mobile-view {
  .mock-tabs {
    .MuiAppBar-root {
      display: none;
      width: 28px;
      overflow: hidden;
      transition: width 0.5s;

      .Mui-disabled {
        width: 0;
      }
    }
  }
}


@media (min-width: 320px) and (max-width: 767px) {
  .web-tab-view {
    display: none;
  }
  .dashboard-mobile-view {
    display: block;

    .mock-tabs {
      width: 100%;
      margin: 0;

      .tabs {
        position: fixed;
        bottom: 0;
        border-top: 1px solid #ddd;
        padding: 0;
        display: block;
        left: 0;

        .MuiTabs-indicator {
          background-color: transparent;
        }

        .button-mock-tabs {
          padding: 6px;

          span {
            font-size: 11px;
          }
        }

        .dashboardTab {
          display: block;
        }
      }

      &.fixed-header {
        .tabs {
          top: inherit;
          left: 0;
          width: 100%;
        }
      }
    }

    &.remove-height {
      .mock-tabs {
        .tabs {
          height: auto;
        }
      }
    }
  }

}

@media (max-width: 767px) {
  .mobile-learning-corner {
    display: block;
  }
  .web-learning-corner {
    display: none;
  }
  .mock-test-section {
    .test-heading {
      width: 100%;
      margin: 60px 0 0px;

      h2 {
        padding: 0 25px 0;
      }
    }

    .mock-tabs {
      width: 100%;
      margin: 0;

      .tabs {
        position: fixed;
        bottom: 0;
        border-top: 1px solid #ddd;
        padding: 0;
        display: block;
        left: 0;

        .MuiTabs-indicator {
          background-color: transparent;
        }

        .button-mock-tabs {
          padding: 6px;

          span {
            font-size: 9px;
          }
        }

        .dashboardTab {
          display: block;
        }
      }

      &.fixed-header {
        .tabs {
          top: inherit;
          left: 0;
          width: 100%;
        }
      }
    }
  }
  .practice-question-block {
    .practice-test {
      .practice-question-heading {
        h4 {
          text-align: center;
          display: none;
        }
      }
    }
  }
}

.breadcrumb-test-series {
  margin-bottom: 3px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  padding: 10px;
  border-radius: 5px;

  ol {
    padding-left: 10px;

    li {
      p {
        color: #212121;
        padding: 0;
        margin: 0;
        font-weight: 400 !important;
      }
    }
  }
}

@media (max-width: 767px) {
  .recharts-tooltip-item-list {
    max-width: 100% !important;
  }
  .breadcrumb-test-series {
    padding: 10px 2px;
    display: none;

    ol {
      li {
        &:nth-child(even) {
          margin-left: 2px;
          margin-right: 2px;
        }

        p {
          font-size: 12px;
        }
      }
    }
  }
  .test-section {
    h4 {
      padding: 0 !important;
    }
  }
  .learning-corner {
    .learning-corner-card {
      .learn-icon {
        h4 {
          width: 100%;
          font-size: 12px;
        }
      }
    }
  }
  .exam-board {
    width: 100%;
    margin: 0;
    box-shadow: none;
    border-radius: 0;
    padding: 0 0 10px;
    border: 0;

    &.profile-board {
      width: 100%;
      display: none;

      &.mobile-profile {
        display: inline-block;
        margin-bottom: 0;
        max-width: 100%;
        padding: 40px 20px 40px;
        border-radius: 0;
        margin-left: 0;
        min-height: calc(100% + 20px);
        height: calc(100% + 20px);
        margin-right: 0;
        width: 100%;
        margin-top: -20px;
        background-color: #768fff;
      }
    }

    &.learning-board {
      width: 100%;
      box-shadow: none;
      margin: 0;
      float: left;
      padding: 20px 0;
    }

    &.mock-test-strip {
      max-width: calc(100% + 16px);
      box-shadow: none;
      padding: 20px 0;
      display: block;
      border-radius: 0;
      background: #f0f4ff;
      margin: 0;
      border: none;
      width: 100%;

      .flexBox-container {
        display: flex;
        overflow: auto;
        width: 100%;
        flex-direction: row;
        flex-wrap: nowrap;

        &::-webkit-scrollbar {
          width: 0 !important;
          height: 0;
        }

        > div {
          min-width: 300px;
          margin-right: 10px !important;
        }
      }

      .mock-test-card {
        width: 100% !important;
        margin: 0 auto 20px !important;
        float: left;
        min-height: inherit !important;
        display: flex !important;
        height: inherit !important;
      }
    }

    &.card-view {
      width: 100%;
      padding: 20px 0px;
      box-shadow: none;
      background: none;
      margin: 0;
      float: none;
      overflow: inherit;

      .flexBox-container {
        display: flex;
        overflow: auto;
        width: 100%;
        flex-direction: row;
        flex-wrap: nowrap;

        &::-webkit-scrollbar {
          width: 0 !important;
          height: 0;
        }

        > div {
          min-width: 300px;
          margin-right: 10px;
        }
      }

      .card-list {
        width: 100% !important;
        float: left;
        box-shadow: none;
        border: 1px solid #ddd;

        .date-time {
          span {
            margin-left: 0;
          }
        }

        h4 {
          min-height: initial;
          max-height: inherit;
          padding: 10px 10px 0;
          -webkit-line-clamp: inherit;
          font-size: 14px;
          border-bottom: 0;
          margin: 0;
        }
      }
    }

    .exam-section {
      .exam-name-card {
        .exam-img {
          width: 65%;

          img {
            max-width: 50px;
            margin-right: 5px;
            height: 50px;
            border-radius: 0%;

          }

          h4 {
            font-size: 12px;
            width: 100%;
            text-overflow: clip !important;
            white-space: nowrap !important;
          }
        }

        .exams-number-attempted {
          width: 35%;

          span {
            margin-right: 5px;
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .exam-board {
    .card-view {
      .flexBox-container {
        &::-webkit-scrollbar {
          width: 0 !important;
          height: 0;
        }

        > div {
          min-width: 250px;
        }
      }
    }

    .exam-heading {
      margin-bottom: 15px;

      h2 {
        font-size: 15px !important;
      }
    }

    .exam-section {
      .exam-name-card {
        width: 42%;
        float: left;
        background: #fff;
        padding: 15px 15px;
        margin-right: 2%;
        border-radius: 4px;
        margin-bottom: 20px;
        height: 163px;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 4px 10px rgba(0, 0, 0, 0.2);
        position: relative;
        margin-right: 8%;

        .exam-img {
          vertical-align: top;
          display: block;
          text-align: center;
          width: 100%;
          margin: 0;
          padding: 10px 10px;

          img {
            height: 70px;
            min-width: inherit;
            object-fit: contain;
            max-width: inherit;
            margin-right: 0;
            width: 70px;
          }

          .exam-data {
            display: inline;
            width: 60px;
            margin-left: 0;
          }

          h4 {
            font-size: 12px;
            margin-bottom: 3px;
            margin-top: 8px;
          }

          span {
            font-size: 13px;
            color: black;
          }

        }

        .exams-number-attempted {
          i {
            display: none;
          }

          span {
            margin-right: 5px;
            font-size: 14px;
          }
        }
      }
    }

    &.mock-test-strip {
      width: 100%;
      background: none;
      float: none;
      overflow: inherit;

      .flexBox-container {
        width: 104%;

        &::-webkit-scrollbar {
          width: 0 !important;
          height: 0;
        }

        > div {
          min-width: 230px;
        }
      }

      .mock-test-card {
        width: 48%;
        margin-right: 2%;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1100px) {
  .exam-board {
    .exam-heading {

      h2 {
        font-size: 15px !important;
      }
    }

    .exam-section {
      .exam-name-card {
        width: 30%;
        float: left;
        background: #fff;
        padding: 15px 15px;
        margin-right: 2%;
        border-radius: 4px;
        margin-bottom: 20px;
        min-height: inherit;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 4px 10px rgba(0, 0, 0, 0.2);
        position: relative;

        .exam-img {

          img {
            max-width: 25px;
            margin-right: 5px;
            height: 25px;
          }

          h4 {
            font-size: 12px;
          }
        }

        .exams-number-attempted {

          span {
            margin-right: 5px;
            font-size: 12px;
          }
        }
      }
    }

    &.mock-test-strip {
      .mock-test-card {
        width: 48%;
        margin-right: 2%;
      }
    }
  }
}

@media (min-width: 1101px) and (max-width: 1316px) {
  .container {
    .mock-test-section {
      .mock-tabs {
        &.fixed-header {
          .tabs {
            width: 100%;
            left: 0;
            right: 0;
          }
        }
      }
    }

    &.open-drawer {
      .mock-test-section {
        .mock-tabs {
          &.fixed-header {
            .tabs {
              width: calc(100% - 240px);
              left: 240px;
            }
          }
        }
      }
    }
  }
  .exam-board {
    .exam-section {
      .exam-name-card {
        width: 29%;
        float: left;
        background: #fff;
        padding: 15px 15px;
        margin-right: 2%;
        border-radius: 4px;
        margin-bottom: 20px;
        min-height: inherit;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 4px 10px rgba(0, 0, 0, 0.2);
        position: relative;

        .exam-img {
          width: 65%;

          img {
            max-width: 25px;
            margin-right: 5px;
            height: 25px;
          }

          h4 {
            font-size: 12px;
          }
        }

        .exams-number-attempted {
          width: 35%;

          span {
            margin-right: 5px;
            font-size: 12px;
          }
        }
      }
    }

    &.mock-test-strip {
      .mock-test-card {
        width: 48%;
        margin-right: 2%;
      }
    }
  }
  .mock-test-strip {
    &.slider {
      .mock-test-card {
        width: 94% !important;
      }
    }

    .mock-test-card {
      width: 31% !important;
      margin-right: 2% !important;

      .tags {
        min-height: 20px !important;
      }
    }
  }
}

.bars-icon {
  position: absolute;
  bottom: -10px;
  left: 40px;
  right: 0;
  text-align: center;
  margin-top: -20px;

  .title-box {
    width: auto;
    display: inline-block;
    margin-right: 20px;

    span {
      display: inline-block;
      vertical-align: middle;

      &:first-child {
        width: 20px;
        height: 12px;
        display: inline-block;
        vertical-align: sub;
        margin-right: 8px;
      }
    }
  }
}

.bar-chart {
  position: relative;

  p.verticle {
    transform: rotate(-90deg);
    position: absolute;
    left: -50px;
    top: 50%;
    margin-top: -11.5px;
  }
}

.recharts-tooltip-item-list {
  width: 100% !important;
  max-width: 100% !important;
  text-align: center;

  li {
    float: left !important;
    width: 100% !important;
    padding: 0 !important;
    border-right: none !important;
  }
}

.tag-line {
  margin: 0 0 10px;
  font-size: 16px;
}

.recharts-tooltip-item:last-child {
  width: 100%;
}

.recharts-tooltip-label {
  display: none;
}

.recharts-tooltip-item-unit {
  display: none;
}

@media (max-width: 767px) {
  .exam-board {
    &.card-view {
      .flexBox-container {
        width: 104%;

        &::-webkit-scrollbar {
          width: 0 !important;
          height: 0;
        }

        > div {
          min-width: 220px;
          display: flex;
          flex-direction: column;
          padding: 0;
          min-height: inherit;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .mock-test-strip {
    background: none;
    width: 110%;

    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0;
    }

    > div {
      min-width: 250px;
      margin-right: -3px;;
    }
  }
}

@media (max-width: 767px) {
  .analysis-component {
    box-shadow: none !important;
    border-radius: 5px;

    > div {
      margin-bottom: 15px;
      background-color: #EFF5F4;
      padding: 0 5px;

      .MuiAccordionSummary-root {
        margin-bottom: 0px;
        padding: 0;
        min-height: inherit;

        .MuiAccordionSummary-content {
          margin: 0;
        }
      }

      .MuiAccordionDetails-root {
        padding: 0;
        width: 104%;
      }
    }
  }
  .card-view {
    width: 100%;
    overflow: inherit;

    .card-list-view {
      & h4 {
        padding: 0 10px;
        border-bottom: 0;
        flex: 1;
        font-size: 14px;
        margin: 0;
      }

      .flexBox-container {
        padding: 2px 1px;

        &::-webkit-scrollbar {
          width: 0 !important;
          height: 0;
        }

        > div {
          min-width: 240px;
          width: 48% !important;
          margin-left: 0;
          padding: 0;
          min-height: inherit;
          display: flex;
          flex-direction: column;
        }

        &.saved-quiz-container {
          > div {
            width: 49% !important;
            margin-right: 0 !important;
            margin-bottom: 10px !important;
            min-width: 94% !important;
            margin-left: 0;
            padding: 10px;

            h4 {
              font-size: 14px;
            }
          }
        }
      }
    }

    &.test-series {
      padding: 0;
      width: 100%;
      flex-wrap: wrap !important;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      .card-list {
        min-width: 100% !important;
        margin-right: 0;
        max-width: inherit !important;
        width: 100% !important;

        &.quiz-list {
          min-width: 60% !important;
          margin-right: 10px;
          max-width: inherit !important;
          width: 60% !important;
          flex: inherit;
          margin-right: 20px;
          margin-left: 0;
        }
      }
    }
  }
  .exam-board {
    .card-view {
      .flexBox-container {
        &::-webkit-scrollbar {
          width: 0 !important;
          height: 0;
        }

        > div {
          min-width: 240px;
        }
      }
    }

    .exam-heading {
      h2 {
        font-size: 15px !important;
      }

      a {
        display: none;
      }
    }
  }
  .saved-question-list {
    margin: 1px 0 !important;
    padding: 0;
  }

  .practice-question-block {
    .mobile-sidebar-btn {
      display: none;
      position: fixed;
      left: 0;
      right: 0;
      background-color: vars.$white-color;
      color: vars.$primary-color;
      text-align: center;
      bottom: 0;
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 4px 10px rgba(0, 0, 0, 0.2);
      font-size: 20px;
      font-weight: bold;
      z-index: 9999;
      height: 51px;
      line-height: 50px;
      cursor: pointer;
    }

    .sidebar {
      transform: translate(0, 100%);

      &.sidebar-open {
        transform: translate(0);
        width: 100%;
        left: 0;
        border-right: none;
        height: calc(100vh - 107px);
        z-index: 9;
        top: 56px;
        background: vars.$white-color;
      }

      .chapter-list {
        height: calc(100vh - 183px);

      }
    }

    .practice-test {
      width: 100%;
    }

    .mobile-search {
      display: block;
      right: 0;
      left: 0;
      position: fixed;
      z-index: 9999;
      background: #fff;
      bottom: 0;
      text-align: center;
      box-shadow: 0 0 11px rgba(0, 0, 0, 0.2), 0 10px 15px rgba(0, 0, 0, 0.2);
      border-top: 1px solid #ddd;

      button {
        background-color: transparent;
        font-size: 16px;
        text-transform: capitalize;
        width: 100%;
        font-weight: bold;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
  .saved-question-section {
    padding: 0;

    .saved-question-list {
      margin: 20px 0;
      padding: 0;
    }

    .saved-question-heading {
      width: 100%;
      padding: 10px 0;
      margin: 0;
      border-bottom: 0;

      h2 {
        font-size: 16px;
      }

    }
  }
  .mock-test-strip {
    .mock-test-card {
      margin: 0px !important;
      width: 94% !important;
      display: inline-block !important;
      min-height: initial !important;
      max-height: initial !important;

      .tags {
        min-height: 33px !important;
      }

      .inner-content {
        .title {
          min-height: initial;
          max-height: initial;
        }
      }
    }
  }
  .bar-chart {
    p.verticle {
      left: -50px;
    }
  }
  .bars-icon {
    left: 0;
    bottom: -20px;

    .title-box {
      margin-right: 10px;
      font-size: 12px;
    }
  }
}

@media (max-width: 667px) and (orientation: landscape) {
  .exam-board {
    .card-view {
      .flexBox-container {
        &::-webkit-scrollbar {
          width: 0 !important;
          height: 0;
        }

        > div {
          min-width: 250px;
        }
      }
    }

    &.profile-board {
      max-width: 50%;
      float: left;
    }

    &.mock-test-strip {
      .mock-test-card {
        width: 48%;
        margin: 0 1% 20px !important;
      }
    }

  }
  .mock-test-strip {
    .mock-test-card {
      margin: 10px 10px !important;
      width: 46% !important;
      float: left !important;
      display: inline-block !important;
      min-height: 214px !important;

      .tags {
        min-height: 33px !important;
      }
    }
  }
  .card-view {
    height: 474px !important;

    &.test-series {
      height: inherit !important;
    }

    .card-list {
      width: 48% !important;
      margin: 20px 1% 20px !important;
      float: left !important;
    }
  }
}

@media (min-width: 668px) and (max-width: 767px) and (orientation: landscape) {
  .exam-board {
    &.profile-board {
      max-width: 50%;
      float: left;
    }

    &.mock-test-strip {
      .mock-test-card {
        width: 31%;
        margin: 0 1% 20px !important;
      }
    }

  }
  .card-view {

    .card-list {
      width: 31% !important;
      float: left !important;
      margin: 0 1% 20px !important;
    }
  }
  .mock-test-strip {
    .mock-test-card {
      width: 31% !important;
      margin: 0 1% 20px !important;
    }
  }
}


@media (max-width: 1100px) and (min-width: 1025px) {
  .analysis-component {
    > div {
      .MuiAccordionDetails-root {
        width: 104%;
      }
    }
  }
  .container {
    .mock-test-section {
      .mock-tabs {
        &.fixed-header {
          .tabs {
            width: 100%;
            left: 0;
            right: 0;
          }
        }
      }
    }

    &.open-drawer {
      width: calc(100% - 240px) !important;

      .mock-test-section {
        .mock-tabs {
          &.fixed-header {
            .tabs {
              width: calc(100% - 240px);
              left: 240px;
            }
          }
        }
      }
    }
  }
  .exam-board {
    .exam-section {
      .exam-name-card {
        .exam-img {
          width: 65%;

          img {
            max-width: 25px;
            margin-right: 5px;
            height: 25px;
          }

          h4 {
            font-size: 12px;
          }
        }

        .exams-number-attempted {
          width: 35%;

          span {
            margin-right: 5px;
            font-size: 12px;
          }
        }
      }
    }

    &.mock-test-strip {
      .mock-test-card {
        width: 48%;
        margin-right: 2%;
      }
    }
  }
  .mock-test-strip {
    &.slider {
      .mock-test-card {
        width: 94% !important;
      }
    }

    .mock-test-card {
      width: 31% !important;
      margin-right: 2% !important;
      max-height: 248px !important;
      min-height: 248px !important;

      .tags {
        min-height: 20px !important;
      }
    }
  }
}

.banner {
  display: none;
}

@media (min-width: 320px) and (max-width: 767px) {
  .banner {
    background-color: #2962FF;
    width: 100%;
    height: auto;
    border-radius: 5px;
    padding: 10px;
    display: block;
    position: relative;

    .site-logo {
      img {
        display: block;
        width: 120px;
      }
    }

    .banner-left {
      width: 190px;
      display: flex;
      flex-direction: column;

      h2 {
        float: left;
        font-size: 16px;
        color: white;
        font-weight: 100;
        padding: 5px;
        margin-bottom: 0px;
        text-align: left;
      }

      p {
        float: right;
        color: #ddd;
        font-size: 10px;
        padding: 5px;
        font-weight: 100;
        text-align: left;
      }

      span {
        display: inline-flex;
        background: #008e76;
        color: #fff;
        font-size: 12px;
        width: 100px;
        margin-left: 5px;
        justify-content: center;
        padding: 6px 12px;
        border-radius: 5px;
      }
    }

    .banner-right {
      width: 160px;
      float: right;
      display: block;

      img {
        position: absolute;
        top: 30px;
      }
    }
  }
  .web-test-dashboard {
    display: block;
  }


  @media (min-width: 320px) and (max-width: 767px) {
    .web-test-dashboard {
      display: none;
    }
    .mobile-test-dashboard {
      display: block;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    .header-bar header > div {
      min-height: 60px !important;
    }
    .container {
      &::-webkit-scrollbar {
        width: 0px !important;
      }
    }
    .card-view {
      .test-series {
        width: 100%;
      }
    }
  }

}

@media (min-width: 768px) and (max-width: 1100px) {
  .Attempted {
    > div {
      padding: 0 !important;
    }
  }
  .practice-question-block {
    .sidebar {
      display: none;
    }
  }
  .practice-question-block .practice-test {
    width: 100%;
  }
}

.webBox-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .suggested-test {
    width: 31% !important;
    margin-right: 2%;
    margin-bottom: 2%;
    display: flex;

    .label-field {
      @media (max-width: 767px) {
        padding: 10px 0 0 !important;
      }
    }

    > div {
      width: 100% !important;
      margin-right: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}


.section-name-head {
  @media (max-width: 767px) {
    padding: 0 !important;
    margin-bottom: 10px !important;
  }
}

.quiz-card-img {
  position: absolute;
  width: 60px !important;
  height: 60px !important;
  min-width: 60px;
  top: 50%;
  margin-top: -36px;
  right: 5px;
  display: flex;
  @media (min-width: 768px) and (max-width: 1250px) {
    display: none;
  }
}

.MuiTabs-indicator {
  background-color: transparent !important;
}
