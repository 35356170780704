  .login-new-btn {
    width: 100%;
    font-size: 16px !important;
    font-weight: 600 !important;
    border-radius: 3px !important;
    padding: 10px 20px !important;
    border: none !important;
    cursor: pointer !important;
    height: 48px;
    text-transform: capitalize !important;

  }
  .login-title {
    text-align: center;
    font-weight: 600;
    font-size: 32px;
    padding-bottom: 28px;
  }
  .verify-domain {
    text-align: center;
    font-weight: 600;
    font-size: 32px;
    padding-bottom: 28px;
    @media (max-width: 767px) {
      font-size: 24px;
    }
  }
  .login-parent-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin: auto;
    height: 100svh;
  }
  .login-child-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    width: 50%;
    background-color: #fff;
  }
  .login-logo {
    max-width: 100%;
    height: auto;
    width: 300px;
    margin-bottom: 40px;
  }
  .login-right-side-box {
    padding: 50px 140px;
    background-color: #f7f8f9;
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .login-new-btn:hover {
    background-color: #063a56;
  }
  .login-forgot-password-div {
    display: flex;
    justify-content: flex-end;
    margin-top: -12px;
    margin-bottom: 40px;
  }
  .login-forgot-password-text {
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    margin-left: auto;
    font-weight: 600;
    margin-bottom: 50px;
  }
  .parent-login-button-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
    margin-top: 16px;
    text-transform: capitalize !important;
    button {
      @media (max-width: 767px) {
        font-size: 14px !important;
      }
    }
  }
  .login-microsoft-div {
    width: 100%;
    font-size: 18px !important;
    font-weight: 600 !important;
    border-radius: 4px;
    border: none;
    align-items: center;
    justify-content: center;
    text-transform: capitalize !important;
    display: flex;
    height: 45px;
  }
  .login-microsoft-button-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  .toggle-password-icon {
    position: absolute;
    right: 24px !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    cursor: pointer;
    font-size: 1.4em !important;
    color: #0C3450 !important;
  }

  #login-img-learn {
    width: 300px !important;
  }

  .email-field {
    margin-bottom: 12px !important;
  }

  .input-box {
    display: block;
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 6px 12px;
    border-radius: 4px;
    width: 100%;
    background: none;
    text-align: left;
    color: rgba(0, 0, 0, 12);
    border: 1px solid rgba(0,0,0,.1294117647);
    .Mui-focused {
      border-color: red !important;
    }
  }

  .MuiInput-underline:before {
    border-bottom: none !important;
    transition: none !important;
  }

  .MuiInput-underline:after {
    transition: none !important;
    border-bottom: 1px solid rgba(0,0,0,.1294117647) !important;
  }

  .Mui-focused {
    outline: none !important;
    border-bottom: 1px solid rgba(0,0,0,.1294117647) !important;
    box-shadow: none !important;
    transition: none !important;
  }
  
  .disabled-btn {
    background-color: #6B728075 !important;
    color: #fff !important;
    cursor: not-allowed !important;
    pointer-events: none; /* Prevent any interaction */
}

@media only screen and (max-width: 1300px) {
  .login-right-side-box {
    padding: 50px;
  }
}

  @media only screen and (max-width: 767px) {
    #login-img-learn {
      width: 140px !important;
    }
    .login-parent-div {
      flex-direction: column;
      background-color: #fff;
    }
    .login-right-side-box{
      padding: 0 15px;
      width: 100%;
      display: flex;
      background-color: transparent;
      margin-top: -80px
    }
    .login-child-div {
      padding: 0px;
      height: 20vh;
    }
    .login-title {
      display: none;
    }
    .login-logo {
      margin-bottom: 0px;
    }
  }
  input#loginPassword::-ms-reveal{
    display:none;
  }

  .domain-input-box.input-box {
    width: 300px;
    border: none;
    border-bottom: 0 !important; 
    border-radius: 0px; 
    padding: 0;
    padding-bottom: 0;
    @media (max-width: 767px) {
      width: auto;
    }
    &::after,&::before {
      display: none !important;
    }
    input {
      border: 1px solid rgba(0, 0, 0, 0.1294117647);
      padding: 10px;
      font-size: 14px;
      border-radius: 5px;
      &:focus {
        border-color: #000;
      }
    }
  }

  .domain-name {
    white-space: nowrap;
    font-size: 12px;
  }

  .change-domain-name-submit-btn {
    button {
      width: 100%;
    }
  }

  .form-input-data {
    width: 400px;
    margin: 0 auto;
    @media (max-width: 767px) {
      width: 100%;
    }
  }