.result-feedback-modal {
  z-index: 9999 !important;

  &.feedback-modal {
    > div {
      + div {
        > div {
          width: 400px;
          padding: 6px 32px 16px;

          .feedback-block {
            width: 100%;

            textarea {
              border: 2px solid #0C3450;
              resize: none;
              width: 100%;
              height: 100px;
              overflow: auto;
              outline: none;
              padding: 10px 5px;
            }
          }
        }
      }
    }
  }

  .feedback-heading {
    width: 100%;
    float: left;
    margin-bottom: 30px;
    text-align: center;

    h2 {
      margin: 0;
      color: #0C3450;
    }
  }

  > div {
    + div {
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1300;
      position: fixed;

      > div {
        width: 600px;
        background: #fff;
        position: relative;
        padding: 26px 32px;

        .feedback-close {
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;

          i {
            color: #888;
            font-size: 18px;
          }
        }

        .feedback-rate {
          width: 100%;
          text-align: center;

          ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
              display: inline-block;
              margin-right: 8px;

              button {
                background-color: transparent;
                border: none;
                padding: 0;
                outline: none;
              }

              i {
                color: #ffcf5a;
                font-size: 30px;

              }
            }
          }
        }

        .feedback-content {
          background: #f8faff;
          padding: 10px 0 10px;
          margin: 20px auto;
          width: 450px;

          h4 {
            text-align: center;
            margin-top: 0;
          }

          ul {
            width: 300px;
            margin: 0 auto;

            li {
              margin-bottom: 10px;
            }
          }
        }

        .feedback-block {
          width: 400px;
          margin: 10px auto;
          display: block;

          textarea {
            border: none;
            border-bottom: 2px solid #0C3450;
            resize: none;
            width: 100%;
            height: 25px;
            overflow: auto;
            outline: none;
          }
        }

        .feedback-submit {
          width: 90%;
          margin: 20px auto 10px;
          display: block;

          button {
            text-align: center;
            width: 100%;
            background-color: #0C3450;
            color: #fff;
            font-size: 16px;
            text-transform: capitalize;

            &:hover {
              background-color: #0C3450;
              opacity: 0.8;
            }

            &:disabled {
              background: #eee;
              cursor: no-drop;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .result-feedback-modal {
    &.feedback-modal {
      > div {
        + div {
          > div {
            width: 340px;
            padding: 6px 27px 16px;

            .feedback-submit {
              width: 90%;
            }
          }
        }
      }
    }
  }
}
