/* Default Fonts */
@font-face {
  font-family: "Poppins";
  src: url("./Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* Certificate Fonts */
@font-face {
    font-family: 'Alex-Brush';
    src: local('Alex-Brush'), url('./AlexBrush-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Literata';
    src: local('Literata'), url('./Literata-VariableFont_opsz,wght.ttf') format('truetype');
}