body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: #fff !important;
}

code {
    font-family: 'Poppins', sans-serif !important;
}

.container:not(.open-drawer) .sidebar {
    left: 55px;
}

.react-confirm-alert-overlay {
    z-index: 9999 !important;
    background: rgba(0, 0, 0, 0.5) !important;
}

.overlay-custom-class-name .react-confirm-alert {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
}

@media (max-width: 767px) {
    body {
      overflow-y: auto;
    }
}