.change-title {
    text-align: center !important;
    font-weight: 600 !important;
    font-size: 32px;
    padding-bottom: 56px !important;
  }
  .mdc-text-field {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 20px 0;
  }
  .heading-change-password {
    left: 18px !important;
    right: 0px !important;
    top: -25px !important;
  }
  .eye-icon-align {
    right: 32px !important;
    top: 50% !important;
  }
  .special-buttons {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .mdc-text-field .mdc-button {
    position: absolute;
    bottom: 20px;
  }

  .mdc-text-field--align-right {
    text-align: right;
  }

  .mdc-text-field--full-width {
    width: 100%;
  }

  .mdc-text-field--expandable {
    min-width: 32px;
    width: auto;
    min-height: 32px;
  }
  .input-box {
    display: block;
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 6px 12px;
    border-radius: 4px;
    width: 100%;
    background: none;
    text-align: left;
    color: rgba(0, 0, 0, 12);
    border: 1px solid rgba(0,0,0,.1294117647);
    .Mui-focused {
      border-color: red !important;
    }
  }

  .MuiInput-underline:before {
    border-bottom: none !important;
    transition: none !important;
  }

  .MuiInput-underline:after {
    transition: none !important;
    border-bottom: 1px solid rgba(0,0,0,.1294117647) !important;
  }

  .Mui-focused {
    outline: none !important;
    border-bottom: 1px solid rgba(0,0,0,.1294117647) !important;
    box-shadow: none !important;
    transition: none !important;
  }
  
  .disabled-btn {
    background-color: #6B728075 !important;
    color: #fff !important;
    cursor: not-allowed !important;
    pointer-events: none; /* Prevent any interaction */
}

.password-box {
  margin-bottom: 12px !important;
}

  .change-password-parent-div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 0px;
    margin: auto;
    height: 100%;
  }
  .change-password-child-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    width: 50%;
    background-color: #fff;
  }
  .change-password-logo {
    max-width: 100%;
    height: auto;
    width: 300px;
    margin-bottom: 40px;
  }
  .change-password-right-side-box {
    padding: 50px 140px;
    background-color: #f7f8f9;
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .toggle-password-icon {
    position: absolute;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 1.2em !important;
    color: #818181;
  }

  .change-password-submit-button {
    width: 100%;
    font-size: 16px !important;
    font-weight: 600 !important;
    border-radius: 3px !important;
    padding: 10px 20px !important;
    border: none !important;
    cursor: pointer !important;
    height: 48px;
    text-transform: capitalize !important;
    margin-top: 10px !important;
  }

   .form-group.error-msg {
    text-align: center;
    font-size: 15px;
    margin-bottom: 15px;
    background: #963327;
    color: #fff;
    display: none;
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: -webkit-fill-available;
}
  .mdc-text-field__label {
    bottom: 0;
    color: rgba(0, 0, 0, 12);
    font-size: 16px;
    pointer-events: none;
    position: absolute;
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
  }

  #login-img-learn {
    width: 300px !important;
  }
  @media only screen and (max-width: 767px) {
    #login-img-learn {
      width: 140px !important;
    }
    .change-password-parent-div {
      flex-direction: column;
      background-color: #fff;
    }
    .change-password-right-side-box{
      padding: 0 15px;
      width: 100%;
      display: block;
      background-color: transparent;
    }
    .change-password-child-div {
      padding: 0px;
      height: 50vh;
    }
    .change-title {
      display: none;
    }
    .change-password-logo {
      margin-bottom: 0px;
    }
  }
  @media only screen and (max-width: 1300px) {
    .change-password-right-side-box {
      padding: 50px;
    }
  }
 
  