@use "./../../themes/variables.scss" as vars;

.profile-modal > div + div > div {
  border-radius: 5px;
  height: 580px;
  width: 800px;
  overflow: auto;
}

.profile-modal > div + div {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1300;
  display: flex;
  justify-content: center;
  align-items: center;
}

.password-modal.profile-modal .profile-information .edit-data.editable-img {
  top: initial;
  z-index: 9;
  right: 20px;
  box-shadow: none;
  bottom: 20px;
  background: #888;
}

.password-modal.profile-modal .profile-information .edit-data.editable-img i {
  color: #fff;
}

.profile-modal > div + div > div .profile-information {
  margin-bottom: 0;
  padding: 30px 20px;
}

.profile-modal > div + div > div .profile-information .personal-details {
  display: block;
}

.tabs-section .accounts-tabbing-content > div {
  padding: 24px 0 !important;
}

.tabs-section header > div > div > div > div + span {
  background-color: #039be5;
}

.container {
  // width: calc(100% - 56px);
  margin-top: 0;
  text-align: left;
  padding: 0px 0px;
  position: relative;
  background: vars.$white-color  ;
  height: auto;
  // margin-bottom: 50px;
  transition: 0.3s linear;
  @media (min-width: 960px) {
    margin-left: 50px;
    width: calc(100% - 50px);
  }

  // @media (max-width: 900px) {
  //   float: right;
  // }

  &.open-drawer {
    width: calc(100% - 240px);
    transition: margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, width 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    margin-left: 240px;
    background: #fafafa;
  }

  .udpate-field {
    display: block;
    margin: 15px 0;
    text-decoration: none;
    color: #fff;
    width: 100%;
    font-size: 14px;
    cursor: pointer;
    font-weight: bold;

    span {
      float: right;
      background: #039be5;
      padding: 5px 10px;
      border-radius: 4px;
    }

    &:hover {
      span {
        opacity: 0.7;
      }
    }
  }

  .verified {
    width: auto;
    float: left;
    margin: 4px 0;
    color: #25cd71;
    font-size: 12px;

    i {
      background: #25cd71;
      color: #fff;
      width: 20px;
      height: 20px;
      vertical-align: middle;
      text-align: center;
      display: inline-block;
      line-height: 20px;
      border-radius: 50%;
      font-size: 14px;
      margin-right: 7px;
    }
  }

  .unverified {
    width: 100%;
    float: left;
    margin: 4px 0;
    color: #cd0000;
    font-size: 12px;
    cursor: pointer;

    i {
      background: #cd0000;
      color: #fff;
      width: 20px;
      height: 20px;
      vertical-align: middle;
      text-align: center;
      display: inline-block;
      line-height: 20px;
      border-radius: 50%;
      font-size: 14px;
      margin-right: 7px;
    }

    &.verify-button {
      float: right;
    }

    .unverified-message {
      width: 100%;
      float: left;
      margin: 10px 0 10px;

      Button {
        float: right;
        background-color: #039be5;
        color: #fff;
        margin-left: 9px;
        font-weight: bold;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }

  .settings {
    width: 100%;
    float: left;
    position: relative;
    z-index: 1;

    h2 {
      margin: 0;
      padding: 10px 0 10px;
      font-size: 24px;
      font-weight: 500;
      border-bottom: 1px solid #eee;
    }

    .tabs {
      background-color: #fff !important;
      box-shadow: none !important;
      color: #000 !important;
      display: none;
    }

    .tabs-section {
      [class|= MuiPrivateTabIndicator] {
        background-color: #039be5;
      }
    }
  }

}

.profile-information {
  width: 100%;
  float: left;
  // border: 1px solid #eee;
  background: #FAFAFA;
  padding-bottom: 50px;
  // margin-bottom: 20px;
  // box-shadow: 0 0 4px rgba(0, 0, 0, 0.2), 0 0 4px rgba(0, 0, 0, 0.2);
  position: relative;
  @media (max-width: 767px) {
    padding: 20px 12px;
    .profile-data {
      margin: 20px 0 0 !important;

      .left-data {
        text-align: center;
        margin-bottom: 0 !important;
      }

      .right-data {
        p {
          justify-content: center;

          span {
            width: auto !important;
          }
        }
      }
    }
  }

  &.enrollment-exams-section {
    .form-group {
      width: 100%;
      margin: 0;

      label {
        font-size: 18px;
      }

      ul {
        padding: 10px 0;
        margin: 0;

        li {
          border-radius: 4px;
          display: inline-block;
          position: relative;
          margin: 0 5px 5px 0;
          font-size: 0.875rem;
          width: auto;
          min-width: 80px;
          padding: 5px 20px;
          color: #fff;
          background: #5F4BAB;

          &:last-child {
            border-bottom: none;
            width: auto;
            display: block;

            &.add-more {
              background: none;
              margin: 0;
              padding: 10px 0 0;

              Button {
                padding: 5px 0;
                margin-top: 0;

                span {
                  color: #5F4BAB;

                  i {
                    color: #5F4BAB;
                    display: inline-block;
                    margin-right: 5px;
                  }
                }

                &:hover {
                  background-color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }

  .profile-data {
    width: 100%;
    float: left;
    margin: 20px 0;

    .left-data {
      width: 50%;
      float: left;
      padding-left: 50px;

      h4 {
        margin: 0;
        padding-bottom: 10px;
        font-size: 18px;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          display: block;
          margin-bottom: 5px;

          i {
            display: inline-block;
            width: 30px;
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            text-align: center;
            border-radius: 50%;
            color: #ffffff;
            margin-right: 10px;
            background: #1fbad6;
            vertical-align: middle;
          }

          span {
            display: inline-block;
            font-size: 14px;
            vertical-align: middle;
            font-weight: bold;
          }
        }
      }

      p {
        margin: 0;
        padding-bottom: 10px;
        font-size: 14px;
      }

      span {
        font-size: 16px;
        color: #000;
      }
    }

    .right-data {
      float: right;
      padding-right: 50px;

      p {
        margin: 0;
        padding-bottom: 10px;
        font-size: 14px;
        display: flex;
        align-items: center;

        i {
          font-size: 16px;
          display: inline-block;
          width: 25px;
          vertical-align: middle;
          text-align: center;

          &.fa-check-circle, &.fa-edit {
            color: #039be5;
          }

          &.fa-exclamation-circle {
            color: #cd0000;
          }
        }

        span {
          display: flex;
          align-items: center;
          margin-left: 6px;
          vertical-align: middle;
          width: calc(100% - 25px);

          button {
            background: transparent;
            border: none;
            cursor: pointer;
            padding: 0;
            outline: none;
          }
        }
      }

    }
  }

  .edit-data {
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 2px 3px rgba(0, 0, 0, .2);
    color: #0073b1;

    i {
      line-height: 30px;
      display: block;
      text-align: center;
      font-size: 17px;
    }
  }

  .profile-banner {
    height: 200px;
    background-size: cover;
    width: 100%;
    position: relative;
    margin-bottom: 50px;
    @media (max-width: 767px) {
      height: auto;
      background: none;
      margin-bottom: 0;
      .profile-pic {
        position: static !important;
        margin: auto;
      }
    }

    .profile-pic {
      width: 150px;
      height: 150px;
      position: absolute;
      bottom: -60px;
      left: 30px;
      border-radius: 50%;
      overflow: hidden;

      input {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }

  .personal-details {
    display: none;

    .form-group {
      width: 46%;
      float: left;
      margin: 30px 0 15px;
      min-height: 60px;
      position: relative;

      &.mobile-number {
        > span {
          top: -3px !important;
        }

        .invalid-error {
          position: absolute;
          color: #f00;
          padding: 5px 0;
          top: inherit !important;
          bottom: -10px;
        }

        select {
          option {
            display: none;
          }
        }

        > div {
          > div {
            margin-top: 16px;

            button {
              color: #f00;
              background-color: transparent;
              border: none;
              text-align: right;
              width: 100%;
              padding: 0;
            }

            + div {
              position: absolute;
              margin-left: 25px;
              margin-top: 3px;
            }
          }
        }

        img {
          height: auto;
          border-radius: 0;
          display: block;
        }

        span {
          position: absolute;
          top: 19px;
          left: 0;
          font-size: 14px;
        }

        input {
          padding-left: 0;
        }

        a.verify-link {
          color: #f00;
          font-size: 14px;
          text-decoration: none;
          position: absolute;
          bottom: -5px;
          right: 0;
        }

        .verified-number {
          position: absolute;
          bottom: -6px;
          right: 0;

          i {
            color: #039be5;
            padding-right: 0;
            position: static;
          }

          span {
            position: static;
            color: #039be5;
          }
        }
      }

      &.about-section {
        width: calc(100% - 3px);
      }

      &:nth-child(even) {
        float: right;
      }

      .text-field {
        width: 100%;
        margin: 0;
      }

      label {
        display: block;
        color: #000;
        font-size: 14px;
        transform: none;
        font-weight: normal;
        margin-bottom: 5px;
      }

      select {
        height: 30px;
        width: 100%;
        outline: none;
        color: #888;
        border: none;
        border-bottom: 1px solid #949494;

        option {
          display: block;
        }

        + div {
          display: none;

          option {
            display: none;
            border-bottom: none;
          }
        }
      }

      .radio-btn {
        label {
          width: auto;
          float: left;
          margin-bottom: 0;
          margin-right: 20px;
          margin-top: 13px;

          span {
            display: inline-block;
            vertical-align: middle;
            font-size: 12px;
          }

          .rbox {
            background-repeat: no-repeat;
            background-size: cover;
            width: 18px;
            vertical-align: middle;
            height: 18px;
            display: inline-block;
            margin-right: 5px;
          }

          input {
            &:checked {
              ~ .rbox {
                background-image: url(https://testbook.com/assets/img/ui-kit/Radio%20Button_Checked.svg);
              }
            }

            ~ .rbox {
              background-image: url(https://testbook.com/assets/img/ui-kit/Radio%20Button_Unchecked.svg);
            }
          }
        }

        input {
          margin: 0;
          width: 0;
          height: 0;
          position: absolute;
          opacity: 0;
        }
      }

      img {
        width: 100%;
        max-width: 70px;
        height: 70px;
        margin-right: 10px;
        border-radius: 50%;
        object-fit: cover;
        display: inline-block;
        vertical-align: middle;
      }

      .input-file {
        display: inline-block;
        position: relative;
        cursor: pointer;
        width: calc(100% - 80px);

        a {
          position: absolute;
          left: 0;
          color: #039be5;
          text-decoration: none;
          font-size: 16px;
          cursor: pointer;
          top: 0;
        }

        input {
          opacity: 0;
          width: 100%;
          cursor: pointer;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
        }
      }

      textarea {
        outline: 0;
        border: none;
        border-bottom: 1px solid #000;
        height: 70px;
        font-size: 14px;
        overflow: auto;
        width: 100%;
        resize: none;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          border-bottom: 1px solid #ddd;
          position: relative;
          margin: 0;
          width: 100%;

          &.add-more {
            a {
              display: inline-block;

              &:hover {
                text-decoration: underline;
              }
            }

            i {
              color: #003acb;
              display: inline-block;
              margin-right: 5px;
            }

            span {
              color: #003acb;
            }
          }

          &:last-child {
            border-bottom: none;
          }

          a {
            background: none;
            text-decoration: none;
            color: #888;
            font-size: 14px;
            font-weight: 500;
            padding: 10px 0;
            display: block;
          }
        }
      }
    }

    .button-submit {
      width: 100%;
      float: left;
      margin: 10px 0 10px;

      Button {
        float: right;
        background-color: #2962ff;
        color: #fff;
        margin-left: 9px;
        font-weight: bold;
        font-size: 14px;
        cursor: pointer;

        i {
          margin-right: 5px;
        }
      }

      a {
        float: right;
        display: inline-block;
        font-size: 14px;
        margin: 0 5px;
        background: #2962ff;
        color: #fff;
        text-decoration: none;
        padding: 7px 20px;
        border-radius: 4px;
        cursor: pointer;
        font-weight: bold;

        i {
          padding-right: 5px;
        }

        &:hover {
          background: #2962ff;
        }
      }
    }
  }
}

.password-modal {
  position: relative;

  .profile-information {

    .form-group {
      input, select, textarea {
        font-size: 14px;
        padding-right: 5px;
      }
    }

    .edit-data {
      cursor: pointer;

      &.close-data {
        top: 0;
        z-index: 9;
        right: 0;
        box-shadow: none;

        i {
          color: #888;

        }
      }

      i {
        top: 0;
        right: 0;
        left: 0;
      }
    }
  }

  &.exam-modal {
    z-index: 9999 !important;

    i {
      right: 10px;
    }

    > div {
      + div {
        > div {
          padding: 0;
          width: auto;
          border-radius: 2px;
        }
      }
    }

    .otp-field {
      position: relative;
      width: 100%;
      float: left;
    }

    .button-submit {
      border-top: 2px solid #eee;
      width: 100%;
      float: left;
      padding: 10px 10px;

      &.resend-otp {
        position: absolute;
        width: auto;
        right: 0;
        bottom: 5px;
        float: none;
        font-size: 10px;
        padding: 0;

        a {
          text-decoration: none;
        }
      }

      button {
        width: auto;
        padding: 0;
        text-align: right;
        border-radius: 2px;

        &:hover {
          opacity: 0.8;
        }

        &:disabled {
          background-color: #eee;

          span {
            color: #aaa;
          }
        }

        span {
          color: #fff;
          justify-content: flex-end;
          padding: 4px 15px;
          font-size: 12px;
        }
      }
    }

    h4 {
      text-align: left;
      margin: 0;
      border-bottom: 0;
      color: #000;
      font-weight: bold;
      padding-bottom: 0;
      padding-left: 10px;
      font-size: 18px;
      padding-top: 10px;
      padding-right: 10px;

      span {
        font-size: 12px;
        color: #888;
        justify-content: flex-start;
        padding-left: 8px;
      }
    }

    p {
      border-bottom: 2px solid #eee;
      margin: 0;
      padding-top: 0;
      font-weight: normal;
      font-size: 12px;
      padding-left: 10px;
      padding-bottom: 6px;
      padding-right: 30px;
      color: #888;
    }

    .add-exam-list {
      margin: 10px 0 20px;
      width: 100%;
      float: left;
      height: auto;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #039be5;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          display: flex;
          text-align: left;
          border-radius: 4px;
          justify-content: flex-start;
          min-width: inherit;
          border: none;
          padding: 4px 10px;
          align-items: center;
          margin: 0;
          background: #fff;
          font-size: 14px;

          > span {
            padding: 5px;
          }

          &:hover {
            background: none;
          }

          &.selected-exam {
            background: none;
            border: none;

            &:hover {
              background: none;
            }

            Button {
              color: #fff;
              font-weight: bold;

              i {
                color: #fff;
              }
            }
          }

          Button {
            padding: 6px 0;

            &:hover {
              background-color: transparent;
            }

            i {
              position: static;
              margin-left: 5px;
              font-size: 12px;
            }

            span {
              font-size: 12px;
              color: #888;
              justify-content: flex-start;
              padding-left: 0;
            }
          }
        }
      }
    }
  }

  &.edit-modal {
    > div {
      + div {
        > div {
          width: 400px;
          padding: 20px;

          .button-submit {
            border-top: 0 !important;
          }

          .text-field {
            margin-bottom: 0;
            margin-top: 20px;
          }

          span {
            color: #f00;
            padding: 5px 0;
            display: inline-block;
            font-size: 12px;
          }
        }
      }
    }
  }

  h4 {
    border-bottom: 3px solid #039be5;
    display: block;
    font-size: 24px;
    padding-bottom: 5px;
    text-align: center;
    margin: 10px auto;
    color: #888;
    font-weight: 400;
  }

  [class|= MuiFormControl] {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .password-confirm {
    width: 100%;
  }

  i {
    position: absolute;
    top: 10px;
    right: 20px;
    color: #888;
    font-size: 16px;
    cursor: pointer;
  }

  > div {
    + div {
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1300;
      position: fixed;

      > div {
        border-radius: 5px;
      }
    }
  }
}

.contact-modal {
  position: relative;

  > div {
    + div {
      > div {
        background: #fff;
        width: 400px;
        padding: 20px 20px;
        position: relative;

        .heading {
          font-size: 16px;
          font-weight: bold;
        }

        .text-field {
          width: 100%;
        }

        .button-submit {
          button {
            float: none;
            display: block;
            margin: 0 auto;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .password-modal > div + div > div {
    padding: 10px 12px;
    width: 90%;
  }
  .contact-modal {
    > div {
      + div {
        > div {
          width: 100%;
          padding: 30px 12px;

          .profile-information {
            h4.heading {
              padding-left: 10px;
            }

            span.title {
              padding-left: 10px;
            }

            .form-group {
              width: 100% !important;
            }
          }

          .button-submit {
            margin-right: 10px !important;
            width: calc(100% - 10px) !important;
          }
        }
      }
    }
  }
  .profile-modal > div + div > div {
    padding: 0 !important;
  }
  .password-modal {
    &.exam-modal {
      &.exam-selector-modal {
        > div {
          + div {
            > div {
              height: 100vh;
            }
          }
        }
      }

      > div {
        + div {
          > div {
            width: auto;
          }
        }
      }

      h4 {
        font-size: 16px !important;
      }
    }

    &.profile-modal {
      > div {
        + div {
          > div {
            width: 100%;
          }
        }
      }

      .profile-information {
        .edit-data {
          &.editable-img {
            width: 20px;
            height: 20px;
            background: #888;
            bottom: 5px;
            right: 13px;

            i {
              line-height: 20px;
              font-size: 12px;
              color: #fff;
            }
          }
        }
      }
    }

    .profile-information {
      width: 100%;
      padding: 30px 0 !important;

      .form-group {
        padding: 0 10px;
        width: 100% !important;

        &.mobile-number {
          .verified-number {
            right: 10px;
            bottom: -10px;
          }

          a.verify-link {
            right: 10px;
          }

          span {
            left: 10px;
          }
        }
      }
    }
  }
  .container {
    // padding: 10px 0;
    width: 100%;

    .settings {
      padding: 0;
    }

    &.open-drawer {
      width: 100%;
    }

  }
  .profile-information {
    .form-group {
      width: 100%;
      margin: 0 0 30px 0;
      min-height: inherit;

      select {
        width: 100%;
      }

      .text-field {
        width: 100%;
      }

      .input-file {
        a {
          font-size: 14px;
        }

        img {
          max-width: 50px;
        }
      }
    }
  }
}


// @media (min-width: 901px) and (max-width: 959px) {
//   .container {
//     width: calc(86% - 103px);
//     margin-left: 25%;

//     &.open-drawer {
//       width: calc(100% - 0px) !important;
//       margin-left: 0%;
//       // margin-top: 1%;
//     }
//   }
// }

// @media (min-width: 960px) and (max-width: 1023px) {
//   .container {
//     width: calc(86% - 103px);
//     margin-left: 25%;

//     &.open-drawer {
//       width: calc(100% - 60px) !important;
//       margin-left: 6%;
//       // margin-top: 1%;
//     }
//   }
// }

@media (min-width: 1025px) and (max-width: 1100px) {
  .container {
    // width: calc(100% - 56px) !important;

    // &.open-drawer {
    //   width: calc(100% - 150px) !important;
    // }
  }
}

@media (min-width: 1101px) and (max-width: 1279px) {
  .container {
    width: calc(100% - 56px);

    &.open-drawer {
      width: calc(100% - 240px);
    }
  }
}

@media (min-width: 450px) and (max-width: 600px) {
  .profile-information {
    .profile-banner {
      .profile-pic {
        width: 100px;
        height: 100px;
        bottom: -40px;
        left: 20px;
      }
    }

    .profile-data {
      .left-data {
        padding-left: 0;
      }

      .right-data {
        padding-right: 0;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 449px) {
  .profile-information {
    .profile-banner {
      .profile-pic {
        width: 70px;
        height: 70px;
        bottom: -40px;
        left: 20px;
      }
    }

    .profile-data {
      .left-data {
        padding-left: 0;
        width: 100%;
        margin-bottom: 20px;
        display:flex;
        justify-content:center;
      }

      .right-data {
        padding-right: 0;
        width: 100%;
      }
    }
  }
}


input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
