.drawe {
  .MuiDrawer-paper {
    width: 260px !important;
  }
  .MuiListItemText-primary {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 21px !important;
  }
}
.listItem {
  display: flex;
  gap: 1rem;
  justify-content: center !important;
}
.icon-height {
  height: 20px;
  width: 20px;
}
.listItemDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2rem;
  // gap: 6rem;
  margin-bottom: 2rem;
}
.icons {
  height: 3vh;
}
.languagetabs {
  .MuiListItem-root {
    padding-left: 3rem;
  }
}

.listDiv {
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  padding-right: 20px;
  @media (max-width: 959px) {
    padding-bottom: 20px;
  }
}
