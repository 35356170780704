.quiz-error {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.quiz-error .quiz-error-block {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #eee;
    justify-content: center;
}

.quiz-error .quiz-error-text {
    margin-top: 20px;
    text-align: center;
}

.quiz-error .quiz-error-text h4 {
    padding: 0 20px;
    font-size: 22px;
}

.quiz-error .quiz-error-img img {
    max-width: 300px;
}

.quiz-error .quiz-error-btn a {
    font-weight: 600;
    background-color: #2962ff;
    color: #fff;
    font-size: 16px;
    text-transform: capitalize;
    border: none;
    width: 150px;
    padding: 8px 0;
    text-align: center;
    display: block;
    text-decoration: none;
    border-radius: 3px;
    cursor: pointer;
    margin: 20px 0;
}

.quiz-error .quiz-error-btn a:hover {
    opacity: 0.8;
    text-decoration: none;
}
