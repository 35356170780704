.container {
  &.open-drawer {
    .no-data-found {
      left: 240px;

      .content {
        width: 680px;
      }
    }
  }

  .no-data-found {
    left: 56px;

    .content {
      width: 840px;
    }
  }

  .no-data {
    position: relative;
    width: 100%;
    float: left;
    height: 400px; // added by swap

    .no-data-banner {
      background: #f0f3ff;
      height: 200px; // 180 to 200
      @media (max-width: 767px) {
        background: #fff;
      }
    }

    .no-data-block {
      width: 250px;
      display: block;
      position: absolute;
      top: 100px; // 110 to 100
      right: 0;
      left: 0;
      margin: -65px auto;
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 4px 10px rgba(0, 0, 0, 0.2);

      .no-data-banner {
        background: #f0f3ff;
        height: 120px;
      }

      .no-data-img {
        position: absolute;
        top: 35px;
        left: 0;
        right: 0;

        img {
          width: 100%;
          max-width: 130px !important;
          display: block;
          margin: 0 auto;
        }
      }

      .no-data-text {
        text-align: center;
        padding: 60px 0 20px;

        h4 {
          font-size: 14px;
          color: #808080;
        }

        Button {
          color: #fff;
          background-color: #5F4BAB;
          min-width: inherit;
          padding: 4px 15px;
          font-size: 12px;
          border-radius: 3px;

          &:hover {
            background-color: #5F4BAB;
            opacity: 0.8;
          }

        }
      }
    }
  }
}

.no-data-found {
  text-align: center;
  color: #2962ff;
  padding: 0;
  display: block;
  position: fixed;
  justify-content: center;
  border-radius: 0;
  align-items: center;
  height: calc(100vh - 66px);
  margin: 0;
  top: 66px;
  right: 0;
  background: #d4d4d4;

  .content {
    background: #c8c8c870;
    position: absolute;
    top: 80px;
    padding: 110px 0;
    height: 420px;
    left: 50px;

    .content-top {
      position: absolute;
      top: 80px;
      left: 0;
      right: 0;

      h2 {
        margin: 0;
        color: #000;
        font-weight: normal;
        padding-bottom: 10px;
        font-size: 44px;
        letter-spacing: 2px;
      }
    }

    .content-bottom {
      position: absolute;
      bottom: 70px;
      left: 0;
      right: 0;

      hr {
        width: 60px;
        border-style: double;
        color: #000;
        margin-top: 42px;
        margin-bottom: 18px;
      }

      span {
        color: #000;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 1px;
      }
    }
  }

  img {
    width: 465px !important;
    object-fit: cover;
    float: right;
    height: 100vh;

    &.report-mobile {
      display: none;
    }
  }
}

.sticky-header {
      margin: 0px!important;
      display: flex;
      align-items: center;
      height: 60px !important;
      width: 100% !important;
      position: sticky !important;
      top: 0;
      z-index: 999;
      padding: 1rem !important;
      box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20);
      @media (max-width: 767px) {
       position: fixed !important;
       top: 0px;
      }
    }

@media (min-width: 320px) and (max-width: 1024px) {
  .container {
    &.open-drawer {
      .no-data-found {
        left: 0;

        .content {
          width: 100%;
          background: none;

        }
      }
    }

    .no-data-found {
      background: none;
      top: 56px;
      left: 0;

      .content {
        width: 100%;
        position: absolute;
        padding: 0;
        background: none;
        top: 25px;
        left: 0;

        .content-top {
          top: 20px;
          left: 0;
          right: 0;

          h2 {
            font-size: 28px;
          }
        }

        .content-bottom {
          bottom: 240px;
          left: 0;
          right: 0;
        }
      }
    }
  }
  .no-data-found {
    img {
      display: none;

      &.report-mobile {
        display: block;
        float: none;
        width: 100% !important;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .container {
    .no-data {
      .no-data-block {
        width: 75%;
        bottom: inherit;

        .no-data-img {
          top: 40px;

          img {
            max-width: 130px !important;
          }
        }

        .no-data-text {
          padding: 58px 0 40px;

          h4 {
            font-size: 14px;
            margin-top: 0;
            margin-bottom: 17px;
          }
        }
      }
    }
  }
}

@media (min-width: 1025px) and (max-width: 1279px) {
  .container {
    &.open-drawer {
      .no-data-found {
        left: 0;

        .content {
          width: 680px;
        }
      }
    }

    .no-data-found {
      left: 0;

      .content {
        width: 700px;
        top: 20px;
      }
    }
  }
  .no-data-found {
    .content {
      height: 340px;
    }
  }
}


