.support-ticket-container {
    padding: 0px 16px 100px;
}

@media (min-width: 960px) {
    .support-ticket-container {
        padding: 0px 16px 65px 16px;
    }
}
.create-new {
    width: 160px;
    height: 40px;
    background-color: #0C3450 !important;
    font-weight: 600 !important;
    font-size: 14px;
    color: #fff !important;
    text-transform: capitalize !important;
}
.tab-set {
    border: 0px !important;
    border-bottom: 1px solid transparent !important;
    border-radius: 0px !important;
    text-transform: capitalize !important;
    &:hover {
        background-color: transparent !important;
    }
}
.table-row-heading {
    background: #F4F4F4 !important;
    text-align-last: center !important;
}
.table-heading-data {
    text-align: center !important;
}
.table tbody tr:last-of-type {
    border-bottom: 0px !important;
}
.table tr:last-child td {
    border-bottom: none !important;
}
.support-ticket {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}
.support-table {
    border-radius: 0px !important;
    max-height: calc(100vh - 210px) !important;
    overflow: auto !important;
}
  
.filter-tabs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.tab-set {
    color: rgba(119, 131, 143, 1) !important;
}
.support-id-color {
    color: #0C3450 !important;
    font-weight: 500 !important;    
    border: none;
    border-radius: 0px;
    text-align: center !important;
    background: #fff !important;
    font-size: 14px;
}
.no-record {
    background-color: #fff !important;
    text-align: center !important;
    font-weight: 500 !important;
    color: #888 !important;
}
.support-heading-color {
    color: #0C3450 !important;
    font-weight: 500 !important;
}
.support-ticket-box {
    border-radius: 8px;
    padding: 16px; 
}
.support-items {
    border: none;
    border-radius: 0px;
    text-align: center !important;
    background: #fff !important;
    font-weight: 500 !important;
    font-size: 14px;
    color: #888 !important;
}
.description-heading {
    color: rgba(12, 52, 80, 1);
    font-weight: 500 !important;
    text-align: left;
    padding-bottom: 8px;
}
.support-image-preview {
    max-width: 100%;
    max-height: 200px;
    float: left;
}

.accordian-icon {
    text-align: center;
}
.admin-box {
    background: #0c34500d !important;
    padding: 12px;
    border: 1.5px solid #0c3450;
    border-radius: 6px;
}

.admin-description {
    color: #888 !important;
    font-weight: 400 !important;
    text-align: left;
}
.text-status {
    padding: 10px 0px 10px 0px;
    text-align: center;
    font-weight: 600;
    @media (max-width: 767px) {
        font-size: 9px;
    }
}

.description-text {
    color: #888 !important;
    font-weight: 400 !important;
    text-align: left;
}
.parent-box {
    display: flex;
    padding: 16px;
    float: right;
    button {
        margin-right: 15px !important;
        .MuiTouchRipple-root {
            display: none;
        }
        @media (max-width: 767px) {
            margin-right: 0 !important;
        }
    }
}
.support-align {
    text-align: center !important;
}
.MuiPaper-elevation1 {
    box-shadow: none !important;
}
.tabs-align {
    display: flex;
    width: 100%;
    padding: 0px 0px 16px 0px;
    border: none;
    cursor: pointer;
    left: 30px;
    position: absolute;
}
.row-pointer {
    cursor: pointer;
    border-bottom: 0px !important;
}
.heading-bg {
    background: #F4F4F4;
}

@media (min-width: 1440px) {
    .support-items {
        padding: 12px 50px !important;
    }
}
@media (max-width: 549px) {
    .create-new {
        width: 130px;
    }
    .parent-box {
        display: block;
    }
    .tabs-align {
        gap: 2px;
        justify-content: space-between;
        padding-bottom: 10px;
    }
    .support-ticket {
        max-width: 100%;
    }
    .support-table {
        margin-top: 20px;
        padding-bottom: 0px;
        overflow-x: hidden !important;
    }
    .support-items {
        padding: 12px 4px !important;
    }
    .mobile-view-support {
        padding-left: 16px;
        padding-right: 28px;
    }
    .mobile-view-cell-size {
        padding: 12px 8px !important;
    }
    .support-align{
        padding: 12px 8px !important;
    }
}