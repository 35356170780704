
.result-screen-second {
    width: 100%;
    float: left;
    background: #fff;
    padding: 10px 4px;
    box-sizing: border-box;
}

.result-screen-second .head-result-screen {
    background: #00b7c5;
    padding: 20px 0;
}

.result-screen-second .head-result-screen h3 {
    margin-bottom: 0;
    margin-top: 0;
    color: #fff;
}

.result-screen-second .head-result-screen p {
    margin-bottom: 0;
    margin-top: 5px;
    color: #fff;
    font-size: 14px;
}

.result-screen-second .your-rank {
    width: 100%;
    float: left;
    text-align: center;
    display: inline-block;
    margin: 25px 0 25px;
}

.result-screen-second .your-rank h5 {
    font-size: 14px;
    color: #999;
    margin: 0;
    font-weight: normal;
}

.result-screen-second .your-rank p {
    font-size: 18px;
    color: #333;
    font-weight: normal;
    margin: 5px 0 0;
}

.result-screen-second .small-detail {
    width: 100%;
    float: left;
    text-align: center;
    display: inline-block;
    margin: 5px 0 25px;
}

.result-screen-second .share-detail {
    width: 100%;
    float: left;
    text-align: center;
    display: inline-block;
    margin: 25px 0 25px;
}

.result-screen-second .share-detail p {
    font-size: 14px;
    color: #999;
    margin: 0 0 10px;
    font-weight: normal;
    line-height: 25px;
}

.result-screen-second .share-detail a {
    font-size: 14px;
    margin: 0 10px 10px;
    font-weight: normal;
    text-decoration: none;
    background: #3b5998;
    border-radius: 3px;
    color: #fff;
    padding: 7px 15px;
}

.result-screen-second .your-answered {
    width: 100%;
    float: left;
    text-align: center;
    display: inline-block;
    margin: 5px 0 25px;
    border-top: solid 1px #eee;
    padding: 0 100px;
    box-sizing: border-box;
}

.result-screen-second .your-answered ul {
    width: auto;
    display: inline-block;
    padding: 0;
    margin: 0;
}

.result-screen-second .your-answered ul li {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    float: left;
    list-style: none;
    margin: 2px 15px 22px;
    line-height: 30px;
    font-size: 14px;
    color: #fff;
}

.result-screen-second .your-answered ul li.correct-answered-li {
    background: #16b55e;
}

.result-screen-second .your-answered ul li.incorrect-answered-li {
    background: #e54549;
}

.result-screen-second .your-answered ul li.skip-answered-li {
    background: #7e8b91;
}

.result-screen-second .your-answered p {
    font-size: 14px;
    color: #999;
    margin: 16px 0 10px;
    font-weight: normal;
    line-height: 25px;
}

@media (max-width: 767px) {
    .result-screen-first {
        max-height: inherit;
    }

    .result-screen-one {
        padding-left: 5px;
        padding-right: 5px;
    }

}

@media (min-width: 768px) and (max-width: 900px) and (orientation: landscape) {
    .result-screen-first {
        max-height: inherit;
    }
}

.react-confirm-alert-button-group {
    justify-content: center;
}

.react-confirm-alert-body {
    text-align: center;
    width: 100%;
}

.react-confirm-alert-body > h1 {
    margin-bottom: 6px;
    font-size: 24px;
}

.react-confirm-alert-button-group > button {
    background: #333;
    padding: 8px 30px;
    color: #fff;
}

@media (max-width: 991px) {
    .react-confirm-alert-body {
        width: auto !important;
    }
}
