@use "./../../themes/variables.scss" as vars;

.min-height-add {
  min-height: calc(100vh - 205px);
}

.card-view {
  width: 100%;
  float: left;
  padding: 10px 25px;
  overflow: hidden;


  &.test-series .card-list .card-details.test-details ul {
    min-height: inherit;
    margin-bottom: 0;
  }

  &.card-less {
    height: auto;

    .flexBox-container {
      flex-wrap: wrap !important;
    }
  }

  .card-list-view {
    width: 100%;
    float: left;

    .flexBox-container {
      display: flex;
      width: 100%;
      flex-wrap: nowrap;
      @media (min-width: 768px) and (max-width: 1100px) {
        overflow: auto;
        display: flex;
        flex-wrap: nowrap;
        width: 104%;
        padding: 0 30px 0 0px;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  &.test-series {
    height: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    @media (min-width: 768px) and (max-width: 1100px) {
      &.test-series-tabs {
        flex-wrap: wrap;
        width: 100%;

        .card-list {
          width: 48% !important;
          min-width: 48% !important;

          &:nth-child(even) {
            margin-right: 0 !important;
            margin-left: 2% !important;
          }
        }
      }
      flex-wrap: nowrap;
      width: 104%;
      overflow: auto;
      padding: 10px 14px;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    @media (min-width: 501px) and (max-width: 991px) {
      &.test-series-tabs {
        .card-list {
          width: 48% !important;
          min-width: 48% !important;
          max-width: 48% !important;
          flex: inherit !important;
          margin-right: 2%;
          margin-left: 0;

          &:nth-child(even) {
            margin-right: 0;
            margin-left: 2%;
          }
        }
      }
    }
    @media (min-width: 992px) and (max-width: 1100px) {
      &.test-series-tabs {
        .card-list {
          width: 31.33% !important;
          min-width: 31.33% !important;
          max-width: 31.33% !important;
          flex: inherit !important;
          margin-right: 2%;
          margin-left: 0;

          &:nth-child(even) {
            margin-right: 2% !important;
            margin-left: 0 !important;
          }

          &:nth-child(3n) {
            margin-right: 0;
            margin-left: 0;
          }
        }
      }
    }
    @media (min-width: 1101px) and (max-width: 1279px) {
      &.test-series-tabs {
        .card-list {
          width: 31% !important;
          min-width: 31% !important;
          max-width: 33% !important;
          flex: inherit !important;
        }
      }
    }

    .test-availability {
      margin: 0;
      padding: 2.5px 15px;
      border-bottom: 1px solid #dfe4e8;
      color: #008e76;
      font-weight: bold;
      font-size: 12px;
      @media (max-width: 767px) {
        padding: 2.5px 10px;
      }

      span {
        font-size: 10px;
        float: right;
        cursor: pointer;
        @media (max-width: 767px) {
          display: none;
        }
      }
    }

    .test-details-series {
      display: inline-block;
      width: 100%;
      padding-left: 10px;

      h1 {
        box-shadow: #868383 0px 0px 7px;
        padding: 6px;

        i {
          font-size: 14px;
          float: right;
          display: inline-block;
          text-align: center;
        }
      }
    }

    img {
      width: 100%;
      max-width: 70px;
      object-fit: cover;
      height: 120px;
    }

    .card-list {
      max-width: 23%;
      min-width: 23%;
      flex: 1 1;
      width: auto;
      min-height: inherit;
      @media (min-width: 768px) and (max-width: 1100px) {
        max-width: 44%;
        min-width: 44%;
        &.quiz-list {
          max-width: 44%;
          min-width: 44%;
        }
      }

      // .date-time {
      //   font-weight: bold;

      //   span {
      //     font-weight: normal;
      //   }
      // }

      .card-btn {
        position: static;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        margin-bottom: 0;
        padding-top: 5px;
        border-top: 0px solid #dfe4e8;
      }

      h4 {
        min-height: auto;
        text-transform: capitalize;
        max-height: inherit;
        padding-bottom: 8px;
        border-bottom: 1px solid #dfe4e8;
      }

      .card-details {
        &.border-btm {
          border-bottom: none;
          @media (max-width: 767px) {
            min-height: 0px !important;
          }
        }

        ul {
          margin-left: 0;
          margin-bottom: 54px;
          @media (max-width: 767px) {
            min-height: inherit;
            margin-bottom: 0;
          }

          li {
            border-bottom: none;
          }
        }
      }
    }
  }

  // h4 {
  //   margin-top: 10px;
  //   font-size: 18px;
  //   margin-bottom: 6px;
  // }

  .card-list {
    width: 23%;
    float: left;
    background: #fff;
    padding: 15px 0 0;
    display: flex;
    flex-direction: column;
    margin-right: 2%;
    border-radius: 4px;
    margin-bottom: 20px;
    min-height: inherit;
    box-shadow: 0 0 1px rgba(0, 0, 0, .2), 0 4px 10px rgba(0, 0, 0, .2);
    position: relative;

    .label-field {
      // width: 100%;
      // float: left;
      margin-bottom: 0;
      padding: 15px 15px 0px 20px;
      display: flex;
      // align-items: center;
      // justify-content: end;
      @media (max-width: 767px) {
        padding: 5px 7px 0;
      }
      @media (min-width: 768px) and (max-width: 1024px) {
        padding: 5px 7px 0;
      }

      .quiz-name {
        margin: 0px;
        color: #202020;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
      }

      .crownIcon {
        padding-top: 0;
        margin-left: 5px;
        width: 30px;
        height: 18px !important;
      }

      i {
        display: block;
        margin-right: 0px;
        margin-left: auto;
        margin-top: 4px;
        font-size: 18px !important;
        cursor: pointer;

        &.fa-bookmark {
          color: #0C3450;
        }

        &.fa-crown {
          margin-left: 13px;
        }
      }
    }

    .mock {
      display: inline-block;
      margin-right: 0;
      color: #fff;
      padding: 1px 10px 1px 20px;
      background: #0C3450;
      font-size: 12px;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 0;
      float: right;

      &:after {
        content: '';
        display: block;
        position: absolute;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-left: 12px solid #fff;
        left: -1px;
        top: -2px;
      }
    }

    .practice {
      float: right;
      color: #fff;
      display: inline-block;
      font-size: 12px;
      padding: 0 10px;
      border-radius: 4px;
      line-height: 20px;
      height: 20px;
      background: #00bea6;
      position: relative;
      padding-left: 27px;

      &:after {
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 19px solid #fff;
        content: '';
        position: absolute;
        left: -3px;
        top: 0;
      }
    }

    .live {
      float: right;
      color: #fff;
      display: inline-block;
      font-size: 12px;
      padding: 0 10px;
      border-radius: 4px;
      line-height: 20px;
      height: 20px;
      background: #00bea6;
      position: relative;
      padding-left: 27px;

      &:after {
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 19px solid #fff;
        content: '';
        position: absolute;
        left: -3px;
        top: 0;
      }
    }

    .free {
      color: #fff;
      float: left;
      background: linear-gradient(45deg, #03e50aad 17%, #039be5 70%);
      display: inline-block;
      font-size: 12px;
      padding: 0 10px;
      border-radius: 4px;
      line-height: 20px;
      height: 20px;
    }

    h4 {
      min-height: 48px;
      max-height: 48px;
      font-size: 16px;
      line-height: 1.5;
      font-weight: 500;
      overflow: hidden;
      width: 100%;
      margin: .6rem 0;
      position: relative;

      &:after {
        content: "";
        text-align: right;
        position: absolute;
        bottom: 5px;
        right: 0;
        width: 70%;
        height: 0.2em;
        background: linear-gradient(to right, rgba(255, 255, 255, 0), white 50%);
      }
    }

    .tags {
      display: inline-block;
      min-height: 75px;

      span {
        display: inline-block;
        text-align: center;
        background: #003acb;
        font-size: 12px;
        padding: 2px 10px;
        margin-right: 5px;
        margin-bottom: 5px;
        color: #fff;
        border-radius: 4px;

        i {
          margin-right: 5px;
        }
      }
    }

    .date-time {
      font-size: .75rem;
      color: yellow;
      font-weight: 400;
      margin-bottom: .2rem;
      white-space: nowrap;
      width: 100%;
      @media (max-width: 767px) {
        padding: 0;
        padding-right: 60px !important;
      }

      span {
        font-size: .75rem;
        display: inline-block;
        vertical-align: initial;
        padding: 0;
        line-height: 1;
        margin-left: 5px;
      }
    }

    .card-details {
      min-height: inherit;
      @media (max-width: 767px) {
        min-height: inherit !important;
        padding: 0 10px !important;
        margin-bottom: 10px;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        // li {
        //   padding: .5rem 0;
        //   color: #86a1ae;
        //   font-size: .75rem;
        //   border-bottom: 0px solid #dfe4e8;
        //   @media (max-width: 767px) {
        //     padding: .5rem 0 0 !important;
        //     border-bottom: 0 !important;
        //   }

        //   span {
        //     vertical-align: inherit;
        //     min-width: 30px;
        //     margin-right: .375rem;
        //     font-size: .75rem;
        //     color: #000;
        //   }

        //   &:last-child {
        //     border-bottom: none;
        //   }
        // }
      }
    }
    .time-zone {
      margin-top: 25px;
      margin-bottom: 15px;
    }

    .card-btn {
      position: static;
      left: 0;
      right: 0;
      margin: 0;
      margin-top: auto;
      bottom: 10px;
      @media (max-width: 767px) {
        position: static !important;
        margin: 0 !important;
        margin-top: auto !important;
      }

      a {
        text-decoration: none;
      }

      .card-btn {
        position: static;
        margin: 0;
      }

      Button {
        font-weight: 600;
        background-color: vars.$secondary-color;
        color: #fff;
        width: 100%;
        font-size: 16px;
        text-transform: capitalize;

        &:hover {
          background-color: vars.$secondary-color;
          opacity: 0.8;
        }

        a {
          color: #fff;
          text-decoration: none;
          width: 100%;
        }
      }
    }
  }
}

.view-more-section {
  width: 100%;
  float: left;
  text-align: center;
  @media (min-width: 768px) and (max-width: 1100px) {
    display: none;
  }

  &.view-less-section {
    Button {
      &.view-more-btn {
        display: none;
      }

      &.view-less-btn {
        display: block;
      }
    }
  }

  Button {
    font-size: 14px;
    color: #1A155E;
    width: auto;
    margin: 20px auto;
    text-transform: capitalize;

    &.view-more-btn {
      display: block;
    }

    &.view-less-btn {
      display: none;
    }

    i {
      color: #1A155E;
      padding-left: 5px;
      font-size: 14px;

    }

    &:hover {
      background-color: transparent;
    }
  }
}

.mobile-back-to-test {
  display: none;
  color: rgb(255, 254, 254);
  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 60px;
    background: #5F4BAB;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 0 10px;
    z-index: 9999;
    svg {
      fill: #fff;
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    span {
      color: #fff;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.web-back-to-test {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 66px;
  background: #55489D;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0 10px;
  z-index: 9999;

  svg {
    fill: #fff;
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  span {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
  }
}

@media (max-width: 767px) {
  .card-view {
    padding: 10px 0;
    height: auto !important;

    .card-list-view {
      .flexBox-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow: auto;
        width: 104%;
        padding: 0 10px;

        > div {
          min-width: 300px;
          margin-right: 10px;
        }

        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
      }
    }

    h4 {
      padding: 0 10px;
    }

    .card-list {
      width: 100% !important;
      display: block;
      float: none;
      margin: 0 auto 20px;

      h4 {
        padding: 0;

        &:after {
          display: none;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .card-view {
    height: inherit !important;
    padding: 10px 10px !important;

    .card-list {
      width: 44%;
      min-width: 44%;
    }
  }
}

.hideInMobile {
  display: flex;
  margin-top: auto;
  @media (max-width: 767px) {
    display: none;
  }
}
